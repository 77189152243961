/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { sortBy } from 'lodash';
import { useStateValue } from '../../../core/context/StateProvider';
import './SettingsScreen.css';
import { addTablesToUser } from '../../../api/UserAPI';
import i18n from '../../../i18n/config';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const EmployeeTablesModal = (props) => {
  const { onClose, open, employee } = props;
  const [{ token, shop }] = useStateValue();
  const [selectedTables, setSelectedTables] = useState([]);
  const [unselectedTables, setUnselectedTables] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (open === true) {
      if (employee.tables.length > 0 && employee.tables[0] === 'all') {
        setSelectedTables(sortBy(shop.tables, 'number'));
      } else {
        const selectedTablesFromShop = shop.tables.filter((table) =>
          employee.tables.find((tTable) => table.number === tTable)
        );
        setSelectedTables(
          employee && employee.tables ? sortBy(selectedTablesFromShop, 'number') : []
        );
      }
      setUnselectedTables(sortBy(shop.tables, 'number'));
    }
  }, [open]);

  const onSave = async () => {
    try {
      setIsLoading(true);
      let tables = [];
      if (selectedTables.length === shop.tables.length && shop.tables.length > 0) {
        tables = ['all'];
      } else {
        tables = selectedTables.map((table) => table.number);
      }
      const res = await addTablesToUser(tables, employee._id, token);
      if (res && res.success === true) {
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('changeTablesError'),
        });
        setIsLoading(false);
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('changeTablesError'),
      });
      setIsLoading(false);
    }
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const closeModal = (refreshNeeded) => {
    setSelectedTables([]);
    setUnselectedTables([]);
    setIsSaveDisabled(true);
    setIsLoading(false);
    onClose(refreshNeeded);
  };

  return (
    <>
      <AnimatedModal
        dimmer="blurring"
        size="tiny"
        open={open}
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{ borderBottom: 'white', backgroundColor: '#f5f5f9', fontFamily: 'Montserrat' }}
        >
          {i18n.t('assignTables')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => {
              closeModal(false);
            }}
            className="settings-close"
            floated="right"
            circular
          />
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{ paddingTop: 10, backgroundColor: '#f5f5f9' }}
          className="unselectable no-scrollbar"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <p style={{ fontWeight: 600, fontSize: 14, paddingLeft: 2 }}>
                {i18n.t('availableTables')}
              </p>
              <div
                style={{
                  borderRadius: 14,
                  width: '100%',
                  height: 300,
                  overflowY: 'scroll',
                  backgroundColor: 'white',
                }}
                className="split-booking-no-scrollbar"
              >
                {unselectedTables.map((table) => (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        padding: 14,
                        paddingBottom: 8,
                        backgroundColor: selectedTables.find(
                          (tTable) => tTable.number === table.number
                        )
                          ? '#7268cd17'
                          : 'white',
                      }}
                      onClick={() => {
                        const index = selectedTables.findIndex(
                          (tTable) => tTable.number === table.number
                        );
                        if (index === -1) {
                          setSelectedTables([...selectedTables, table]);
                        } else {
                          selectedTables.splice(index, 1);
                          setSelectedTables([...selectedTables]);
                        }
                        setIsSaveDisabled(false);
                      }}
                    >
                      <p style={{ fontWeight: 500, margin: 0 }}>{`${table.number}`}</p>
                    </div>
                    <div
                      style={{
                        backgroundColor: selectedTables.find(
                          (tTable) => tTable.number === table.number
                        )
                          ? '#7268cd17'
                          : 'white',
                        paddingLeft: 10,
                        marginRight: 10,
                      }}
                    >
                      <div style={{ borderBottom: '1px solid rgba(210, 210, 210, 0.35)' }} />
                    </div>
                  </>
                ))}
              </div>
              <Button
                className="settings-btn-cat"
                style={{ paddingLeft: 22, paddingTop: 16, fontFamily: 'Montserrat' }}
                onClick={() => {
                  setIsSaveDisabled(false);
                  setSelectedTables(unselectedTables);
                }}
                disabled={unselectedTables.length === 0}
              >
                {i18n.t('newPrinterSelectAll')}
              </Button>
            </div>
            <div style={{ alignSelf: 'center', fontSize: 26, color: '#7268cd' }}>
              <Icon name="arrow right" />
            </div>
            <div style={{ width: '45%' }}>
              <p style={{ fontWeight: 600, fontSize: 14, paddingLeft: 2 }}>
                {i18n.t('selectedTables')}
              </p>
              <div
                style={{
                  borderRadius: 14,
                  width: '100%',
                  height: 300,
                  overflowY: 'scroll',
                  backgroundColor: 'white',
                }}
                className="split-booking-no-scrollbar"
              >
                {selectedTables.map((table, index) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      padding: 14,
                      paddingBottom: 8,
                      borderBottom: '1px solid rgba(210, 210, 210, 0.35)',
                    }}
                    onClick={() => {
                      selectedTables.splice(index, 1);
                      setSelectedTables([...selectedTables]);
                      setIsSaveDisabled(false);
                    }}
                  >
                    <p style={{ fontWeight: 500, margin: 0 }}>{`${table.number}`}</p>
                  </div>
                ))}
              </div>
              <Button
                className="settings-btn-cat"
                style={{ paddingLeft: 22, paddingTop: 16, fontFamily: 'Montserrat' }}
                onClick={() => {
                  setIsSaveDisabled(false);
                  setSelectedTables([]);
                }}
                disabled={selectedTables.length === 0}
              >
                {i18n.t('unselectAll')}
              </Button>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          <Button
            content={i18n.t('bookingModalEditSave')}
            color="teal"
            floated="right"
            style={{ borderRadius: 10 }}
            disabled={isSaveDisabled}
            onClick={() => onSave()}
            loading={isLoading}
          />
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default EmployeeTablesModal;
