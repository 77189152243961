/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, Table, Pagination, Loader, Accordion } from 'semantic-ui-react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { useMediaQuery } from '@react-hook/media-query';
import { useStateValue } from '../../../core/context/StateProvider';
import './BookingsScreen.css';
import {
  getEmployeeDailyReport,
  getMonthlyReport,
  getMonthlyReportPDF,
  getSummary,
  getZBonDates,
} from '../../../api/BookingAPI';
import ZBonScreenModal from './ZBonScreenModal';
import XBonScreenModal from './XBonScreenModal';
import EmployeeSummaryModal from './EmployeeSummaryModal';
import { Printer } from '../../../utils/Printer';
import 'react-datepicker/dist/react-datepicker.css';
import { showToast } from '../../../components/general/Toast/Toast';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import AnimatedTabs from '../../../components/general/AnimatedTabs/AnimatedTabs';

const DailySummaryModal = (props) => {
  const { onClose, open, role, pin } = props;
  const [{ token, shop }, dispatch] = useStateValue();
  const [dates, setDates] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [zBonModalOptions, setZBonModalOptions] = useState({ open: false, date: null });
  const [xBonModalOptions, setXBonModalOptions] = useState({ open: false });
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [monthlyReportModalOptions, setMonthlyReportModalOptions] = useState({ open: false });
  const [selectedMonth, setSelectedMonth] = useState(moment().toDate());
  const [isLoadingDates, setIsLoadingDates] = useState(false);
  const [employeeSummaryModalOptions, setEmployeeSummaryModalOptions] = useState({
    open: false,
    date: null,
  });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [employeeSummaryDate, setEmployeeSummaryDate] = useState({
    startDate: null,
    startTime: null,
    endTime: null,
    endDate: null,
  });
  const tabs = { new: 'new', past: 'past' };
  const [selectedTab, setSelectedTab] = useState(tabs.new);
  const [passedDays, setPassedDays] = useState([]);

  const matches = useMediaQuery('only screen and (min-width: 766px)');

  useEffect(() => {
    if (open === true && dates.length === 0 && isLoadingDates === false) {
      createDateArray(1);
    }
  }, [open]);

  useEffect(() => {
    const momentDate = moment(employeeSummaryDate.startDate).startOf('day');
    const momentEndTime = moment(employeeSummaryDate.endTime, 'HH:mm');
    const momentStartTime = moment(employeeSummaryDate.startTime, 'HH:mm');
    if (
      momentDate.isSame(moment(employeeSummaryDate.endDate).startOf('day')) &&
      momentStartTime.isAfter(momentEndTime)
    ) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('startTimeError'),
      });
      setEmployeeSummaryDate({
        ...employeeSummaryDate,
        startTime: '',
      });
    }
  }, [employeeSummaryDate.startTime]);

  useEffect(() => {
    const momentDate = moment(employeeSummaryDate.startDate).startOf('day');
    const momentEndTime = moment(employeeSummaryDate.endTime, 'HH:mm');
    const momentStartTime = moment(employeeSummaryDate.startTime, 'HH:mm');
    if (
      momentDate.isSame(moment(employeeSummaryDate.endDate).startOf('day')) &&
      momentEndTime.isBefore(momentStartTime)
    ) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('endTimeError'),
      });
      setEmployeeSummaryDate({
        ...employeeSummaryDate,
        endTime: '',
      });
    }
  }, [employeeSummaryDate.endTime]);

  const createDateArray = async (page) => {
    setIsLoadingDates(true);
    let array = [];
    try {
      const res = await getZBonDates(token, page);
      if (res && res.success) {
        array = res.dates;
        setTotalPages(res.totalPages);
        setIsLoadingDates(false);
      } else {
        array = [];
        setErrorWarningModalOptions({ open: true, message: i18n.t('allDatesError1') });
        setIsLoadingDates(false);
      }
    } catch (error) {
      array = [];
      setErrorWarningModalOptions({ open: true, message: i18n.t('allDatesError1') });
      setIsLoadingDates(false);
    }
    setDates(array);
    setPassedDays(
      array.filter(
        (date) =>
          date.isDone === false && !moment(date.date).startOf('day').isSame(moment().startOf('day'))
      )
    );
  };

  const showPrinterError = (printerDevice) => {
    showToast({
      error: true,
      message: `${i18n.t('printerToastError1')} "${printerDevice.printer.description}" ${i18n.t(
        'printerToastError2'
      )}`,
      title: i18n.t('newPrinterErrorTitle'),
      id: `printErrorDailySummary${printerDevice.printer.description}`,
    });
  };

  const downloadMonthlyReport = async () => {
    setIsDownloadingReport(true);
    try {
      await getMonthlyReportPDF(token, shop._id, moment(selectedMonth).format());
      setIsDownloadingReport(false);
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('monthlyReportError'),
      });
      setIsDownloadingReport(false);
    }
  };

  const printMonthlyReport = async () => {
    setIsLoadingReport(true);
    try {
      const res = await getMonthlyReport(
        moment(selectedMonth).format(),
        shop._id.toString(),
        token
      );
      if (res && res.success) {
        setIsLoadingReport(false);
        if (shop.printers.find((printer) => printer.isMain === true)) {
          const shopPrinter = shop.printers.find((printer) => printer.isMain === true);
          const printerDevice = new Printer(shopPrinter);
          printerDevice.epos.onerror = () => showPrinterError(printerDevice);
          const paperSize = shopPrinter.paperSize === 80 ? 42 : 30;
          printerDevice.printMonthlyReport(
            res.report,
            selectedMonth,
            paperSize,
            shop.tipsAllowed === true,
            shop.allowDiscounts === true
          );
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('printerNoKundenbonError1'),
          });
        }
      } else {
        setIsLoadingReport(false);
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('monthlyReportError'),
        });
      }
    } catch (error) {
      setIsLoadingReport(false);
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('monthlyReportError'),
      });
    }
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const closeModal = () => {
    setTotalPages(0);
    setDates([]);
    setIsLoadingReport(false);
    setPage(1);
    setEmployeeSummaryModalOptions({ open: false, date: null });
    setSelectedMonth(moment().toDate());
    setStartDatePickerOpen(false);
    setEndDatePickerOpen(false);
    setIsDownloadingReport(false);
    setErrorWarningModalOptions({ open: false, message: '' });
    setEmployeeSummaryDate({
      startDate: null,
      startTime: null,
      endTime: null,
      endDate: null,
    });
    setIsLoadingDates(false);
    setSelectedTab(tabs.new);
    setPassedDays([]);
    onClose();
  };

  const onCloseZBonModal = () => {
    setZBonModalOptions({ open: false, date: null });
    createDateArray(1);
    setPage(1);
  };

  const onCloseXBonModal = () => {
    setXBonModalOptions({ open: false });
  };

  const onCloseEmployeeSumaryModal = () => {
    setEmployeeSummaryModalOptions({ open: false, date: null });
  };

  const handlePageChange = async (_, { activePage }) => {
    setPage(activePage);
    createDateArray(activePage);
  };

  const printEmployeeSummary = async () => {
    try {
      setIsLoadingReport(true);
      const startDate = moment(employeeSummaryDate.startDate)
        .startOf('day')
        .add(moment.duration(employeeSummaryDate.startTime))
        .format();
      const endDate = moment(employeeSummaryDate.endDate)
        .startOf('day')
        .add(moment.duration(employeeSummaryDate.endTime))
        .format();
      const res = await getEmployeeDailyReport(startDate, endDate, pin, token);
      if (res && res.success) {
        setIsLoadingReport(false);
        if (shop.printers.find((printer) => printer.isMain === true)) {
          const shopPrinter = shop.printers.find((printer) => printer.isMain === true);
          const printerDevice = new Printer(shopPrinter);
          printerDevice.epos.onerror = () => showPrinterError(printerDevice);
          const paperSize = shopPrinter.paperSize === 80 ? 42 : 30;
          printerDevice.printEmployeeDailyReport(
            res.total,
            res.totalCard,
            res.totalCash,
            startDate,
            endDate,
            res.name,
            res.count,
            shop.name,
            shop.tipsAllowed ? res.totalTipAmount : null,
            shop.tipsAllowed ? res.totalTipCard : null,
            shop.tipsAllowed ? res.totalTipCash : null,
            res.totalOpens,
            res.openTables,
            paperSize
          );
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('printerNoKundenbonError1'),
          });
        }
      } else {
        setIsLoadingReport(false);
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('dailyReportError'),
        });
      }
    } catch (error) {
      setIsLoadingReport(false);
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('dailyReportError'),
      });
    }
  };

  const parseData = () =>
    dates
      .filter((date) => date.isDone === true)
      .map((date) => (
        <Fragment key={`${date.date}div`}>
          <Table.Row negative={date.isDone === false}>
            <Table.Cell verticalAlign="middle" textAlign="center">
              <p style={{ fontWeight: '600' }}>{`${moment(date.date).format('DD.MM.YYYY')}`}</p>
            </Table.Cell>
            <Table.Cell collapsing verticalAlign="middle" textAlign="center">
              <Button
                icon="print"
                content={matches === false ? i18n.t('dailySummaryZbon') : null}
                fluid={matches === false}
                onClick={() => {
                  setZBonModalOptions({ open: true, date: new Date(date.date) });
                }}
                size="large"
                color="blue"
              />
            </Table.Cell>
            <Table.Cell collapsing verticalAlign="middle" textAlign="center">
              <Button
                icon="file pdf outline"
                content={matches === false ? i18n.t('cashPayments1') : null}
                fluid={matches === false}
                onClick={() => getSummary(token, shop._id, moment(date.date).format(), 'cash')}
                size="large"
                color="blue"
              />
            </Table.Cell>
            <Table.Cell collapsing verticalAlign="middle" textAlign="center">
              <Button
                icon="file pdf outline"
                content={matches === false ? i18n.t('cardPayments1') : null}
                fluid={matches === false}
                onClick={() => getSummary(token, shop._id, moment(date.date).format(), 'card')}
                size="large"
                color="blue"
              />
            </Table.Cell>
            <Table.Cell collapsing verticalAlign="middle" textAlign="center">
              <Button
                icon="users"
                content={matches === false ? i18n.t('emplSum1') : null}
                fluid={matches === false}
                onClick={() => {
                  setEmployeeSummaryModalOptions({
                    open: true,
                    date: new Date(date.date),
                  });
                }}
                size="large"
                color="blue"
              />
            </Table.Cell>
          </Table.Row>
        </Fragment>
      ));

  return (
    <>
      <AnimatedModal
        open={open}
        dimmer="blurring"
        size={role === 'employee' ? 'mini' : 'tiny'}
        closeOnEscape
        closeOnDimmerClick
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            backgroundColor: '#f5f5f9',
            fontFamily: 'Montserrat',
            paddingBottom: 0,
          }}
        >
          {role === 'owner' || role === 'manager'
            ? i18n.t('bookingsDailySummary')
            : i18n.t('emplEndOfDay')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        {role === 'owner' || role === 'manager' ? (
          <>
            <div
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                marginTop: 6,
                height: 32,
                marginLeft: -4,
                marginBottom: 14,
              }}
            >
              <AnimatedTabs
                tabs={[
                  { id: 'new', label: i18n.t('newZbon') },
                  { id: 'past', label: i18n.t('pastZbon') },
                ]}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <Modal.Content
          style={{
            backgroundColor: '#f5f5f9',
            scrollbarWidth: 'none',
            paddingTop: 0,
            marginTop: 10,
            paddingBottom: 0,
          }}
          scrolling
          className="no-scrollbar"
        >
          {(role === 'owner' || role === 'manager') && isLoadingDates === true ? (
            <div style={{ height: 200 }}>
              <Loader
                active={isLoadingDates === true}
                size="large"
                style={{}}
                className="bookings-create-loader"
              />
            </div>
          ) : (
            <></>
          )}
          {(role === 'owner' || role === 'manager') &&
          selectedTab === tabs.new &&
          isLoadingDates === false ? (
            <>
              {passedDays.length > 0 ? (
                <div style={{ animation: 'slideIn187Pos 0.2s ease-in-out' }}>
                  <div
                    style={{
                      borderRadius: 14,
                      width: '100%',
                      backgroundColor: 'white',
                      padding: 12,
                      marginBottom: 20,
                      marginTop: 4,
                    }}
                  >
                    <p style={{ fontWeight: 700, fontSize: 16 }}>{i18n.t('newReport')}</p>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 8,
                      }}
                    >
                      <p style={{ fontWeight: 600, fontSize: 15, marginBottom: 0 }}>
                        {moment().format('DD.MM.YYYY')}
                      </p>
                      <div
                        style={{
                          padding: '6px 14px',
                          borderRadius: 8,
                          border: '1px solid #7268cd',
                          backgroundColor: '#7268cd',
                          color: 'white',
                          fontWeight: 600,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setZBonModalOptions({ open: true, date: new Date() });
                        }}
                      >
                        <p>{i18n.t('createNewZbon')}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: 14,
                      width: '100%',
                      backgroundColor: 'white',
                      padding: 12,
                      marginBottom: 20,
                      marginTop: 4,
                    }}
                  >
                    <Accordion
                      panels={[
                        {
                          key: 'revenue-panel',
                          title: {
                            content: (
                              <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                                {i18n.t('passedDays')}
                              </p>
                            ),
                          },
                          content: {
                            content: (
                              <>
                                <div
                                  style={{
                                    maxHeight: 'calc(100vh - 500px)',
                                    overflowY: 'scroll',
                                    scrollbarWidth: 'none',
                                  }}
                                  className="no-scrollbar"
                                >
                                  {passedDays.map((date, index) => (
                                    <>
                                      <div
                                        style={{
                                          display: 'flex',
                                          marginBottom: 16,
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          padding: '0px 4px',
                                        }}
                                      >
                                        <p
                                          style={{ fontWeight: 600, fontSize: 15, marginBottom: 0 }}
                                        >
                                          {moment(date.date).format('DD.MM.YYYY')}
                                        </p>
                                        <div
                                          style={{
                                            padding: '6px 14px',
                                            borderRadius: 8,
                                            border: '1px solid #7268cd',
                                            backgroundColor: '#7268cd',
                                            color: 'white',
                                            fontWeight: 600,
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            setZBonModalOptions({
                                              open: true,
                                              date: new Date(date.date),
                                            });
                                          }}
                                        >
                                          <p>{i18n.t('createNewZbon')}</p>
                                        </div>
                                      </div>
                                      {index !== passedDays.length - 1 ? (
                                        <div
                                          style={{
                                            height: 0,
                                            width: '100%',
                                            borderBottom: '1px solid #eeecfc',
                                            marginBottom: 16,
                                          }}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ))}
                                </div>
                              </>
                            ),
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingTop: 40,
                      paddingBottom: 40,
                      gap: 10,
                    }}
                  >
                    <p style={{ color: '#333', fontWeight: 500, fontSize: 16 }}>
                      {i18n.t('todaysZbonDoneAlready')}
                    </p>
                    <div
                      style={{
                        padding: '6px 14px',
                        borderRadius: 8,
                        border: '1px solid #eeecfc',
                        backgroundColor: '#eeecfc',
                        color: '#7268cd',
                        fontWeight: 600,
                        cursor: 'pointer',
                      }}
                      onClick={() => setSelectedTab(tabs.past)}
                    >
                      <p>{i18n.t('toOldZbons')}</p>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {(role === 'owner' || role === 'manager') &&
          selectedTab === tabs.past &&
          isLoadingDates === false ? (
            <div style={{ animation: 'slideIn187Neg 0.2s ease-in-out' }}>
              <div className="bookings-pagination" style={{ marginBottom: 0 }}>
                <div className="bookings-pagination-center">
                  <Pagination
                    prevItem="<"
                    nextItem=">"
                    activePage={page}
                    totalPages={totalPages}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    boundaryRange={0}
                    onPageChange={(e, props) => handlePageChange(e, props)}
                    pointing
                    secondary
                  />
                </div>
              </div>
              <div
                className="bookings-table-container"
                style={{ marginTop: '10px', height: 'calc(100vh - 400px)' }}
              >
                <Table fixed celled className="bookings-table">
                  {matches === true ? (
                    <>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            width="2"
                            verticalAlign="middle"
                            textAlign="center"
                            style={{ backgroundColor: 'white' }}
                          >
                            {i18n.t('dailySummaryDate')}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width="2"
                            verticalAlign="middle"
                            textAlign="center"
                            style={{ backgroundColor: 'white' }}
                          >
                            {i18n.t('dailySummaryZbon')}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width="2"
                            verticalAlign="middle"
                            textAlign="center"
                            style={{ backgroundColor: 'white' }}
                          >
                            {i18n.t('dailySummaryBar')}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width="2"
                            verticalAlign="middle"
                            textAlign="center"
                            style={{ backgroundColor: 'white' }}
                          >
                            {i18n.t('dailySummaryKarte')}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width="2"
                            verticalAlign="middle"
                            textAlign="center"
                            style={{ backgroundColor: 'white' }}
                          >
                            {i18n.t('emplAbbr')}
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                    </>
                  ) : (
                    <></>
                  )}
                  <Table.Body>
                    {isLoadingDates === true ? (
                      <>
                        <Loader
                          active={isLoadingDates === true}
                          size="large"
                          style={{ marginTop: -100 }}
                          className="bookings-create-loader"
                        />
                      </>
                    ) : (
                      <>{parseData()}</>
                    )}
                  </Table.Body>
                </Table>
              </div>
            </div>
          ) : (
            <></>
          )}
          {role === 'employee' ? (
            <>
              <div
                style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
              >
                <div>
                  <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 700 }}>
                    {i18n.t('begin')}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div
                      style={{ border: 'none', width: 100 }}
                      onClick={() => setStartDatePickerOpen(true)}
                    >
                      <ReactDatePicker
                        className="day-picker-zbon"
                        wrapperClassName="day-picker-wrapper"
                        dateFormat="dd.MM.yyyy"
                        showPopperArrow={false}
                        placeholderText="--"
                        locale="de"
                        minDate={moment().subtract(1, 'days').startOf('day').toDate()}
                        maxDate={moment().endOf('day').toDate()}
                        selected={employeeSummaryDate.startDate}
                        onClickOutside={() => setStartDatePickerOpen(false)}
                        readOnly
                        calendarStartDay={1}
                        open={startDatePickerOpen === true}
                        onChange={(date) => {
                          setStartDatePickerOpen(false);
                          setEmployeeSummaryDate({
                            startDate: date,
                            startTime: '',
                            endDate: null,
                            endTime: '',
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none', marginTop: 10 }}>
                      <input
                        type="time"
                        className="day-picker-zbon"
                        style={{ width: 130 }}
                        onChange={(e) => {
                          if (e.target.valueAsDate) {
                            setEmployeeSummaryDate({
                              ...employeeSummaryDate,
                              startTime: e.target.value,
                            });
                          }
                        }}
                        value={employeeSummaryDate.startTime}
                      />
                    </div>
                  </div>
                  <Button
                    color="teal"
                    fluid
                    content={`0:00${i18n.t('uhrGerman')}`}
                    icon="undo"
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setEmployeeSummaryDate({
                        ...employeeSummaryDate,
                        startTime: '00:00',
                        startDate: moment().startOf('day').toDate(),
                      });
                    }}
                  />
                </div>
                <div style={{ width: 1, height: 80, marginTop: 20, backgroundColor: '#bbb' }} />
                <div>
                  <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 700 }}>
                    {i18n.t('end')}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none' }} onClick={() => setEndDatePickerOpen(true)}>
                      <ReactDatePicker
                        className="day-picker-zbon"
                        wrapperClassName="day-picker-wrapper"
                        dateFormat="dd.MM.yyyy"
                        showPopperArrow={false}
                        placeholderText="--"
                        locale="de"
                        calendarStartDay={1}
                        minDate={
                          employeeSummaryDate.startDate &&
                          moment(employeeSummaryDate.startDate)
                            .startOf('day')
                            .isSame(moment().startOf('day'))
                            ? moment().toDate()
                            : moment().subtract(1, 'days').startOf('day').toDate()
                        }
                        maxDate={moment().endOf('day').toDate()}
                        selected={employeeSummaryDate.endDate}
                        onClickOutside={() => setEndDatePickerOpen(false)}
                        readOnly
                        open={endDatePickerOpen === true}
                        onChange={(date) => {
                          setEndDatePickerOpen(false);
                          setEmployeeSummaryDate({
                            ...employeeSummaryDate,
                            endDate: date,
                            endTime: '',
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none', marginTop: 10 }}>
                      <input
                        type="time"
                        className="day-picker-zbon"
                        style={{ width: 130 }}
                        onChange={(e) => {
                          if (e.target.valueAsDate) {
                            setEmployeeSummaryDate({
                              ...employeeSummaryDate,
                              endTime: e.target.value,
                            });
                          }
                        }}
                        value={employeeSummaryDate.endTime}
                      />
                    </div>
                  </div>
                  <Button
                    color="teal"
                    fluid
                    content={i18n.t('now')}
                    icon="clock"
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setEmployeeSummaryDate({
                        ...employeeSummaryDate,
                        endDate: moment().startOf('day').toDate(),
                        endTime: moment().format('HH:mm'),
                      });
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          {role === 'owner' || role === 'manager' ? (
            <>
              <Button
                floated="right"
                content={i18n.t('monthlyReport')}
                color="teal"
                onClick={() => setMonthlyReportModalOptions({ open: true })}
                disabled={isLoadingReport === true}
                loading={isLoadingReport === true}
              />
              <Button
                floated="left"
                content={i18n.t('xBon')}
                color="teal"
                onClick={() => setXBonModalOptions({ open: true })}
                disabled={isLoadingReport === true}
                loading={isLoadingReport === true}
              />
            </>
          ) : (
            <Button
              floated="right"
              icon="print"
              content={i18n.t('endOfDay')}
              color="blue"
              onClick={() => printEmployeeSummary()}
              disabled={
                isLoadingReport === true ||
                moment(employeeSummaryDate.endDate).isValid() === false ||
                moment(employeeSummaryDate.startDate).isValid() === false ||
                employeeSummaryDate.endTime == null ||
                employeeSummaryDate.endTime === '' ||
                employeeSummaryDate.startTime == null ||
                employeeSummaryDate.startTime === ''
              }
              loading={isLoadingReport === true}
            />
          )}
        </Modal.Actions>
      </AnimatedModal>
      <AnimatedModal
        dimmer="blurring"
        size="mini"
        open={monthlyReportModalOptions.open}
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            paddingBottom: 0,
            backgroundColor: '#f5f5f9',
            fontFamily: 'Montserrat',
          }}
        >
          {i18n.t('monthlyReport')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => setMonthlyReportModalOptions({ open: false })}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content style={{ backgroundColor: '#f5f5f9' }}>
          <div style={{ display: 'table', margin: '0 auto', backgroundColor: '#f5f5f9' }}>
            <ReactDatePicker
              inline
              locale="de"
              container
              wrapperClassName="datepicker"
              showMonthYearPicker
              dateFormat="MM/yyyy"
              selected={moment(selectedMonth).toDate()}
              minDate={moment(shop.created).startOf('month').toDate()}
              maxDate={moment().toDate()}
              onChange={(selectedDate) => {
                const date = moment(selectedDate).startOf('month').startOf('day');
                if (date.isSameOrBefore(moment().startOf('month').startOf('day'))) {
                  setSelectedMonth(date.toDate());
                }
              }}
            />
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          <Button
            content={i18n.t('print')}
            icon="print"
            color="blue"
            onClick={() => printMonthlyReport()}
            loading={isLoadingReport}
            disabled={isLoadingReport}
          />
          <Button
            content={i18n.t('appPaySuccess7')}
            icon="download"
            color="teal"
            floated="left"
            onClick={() => downloadMonthlyReport()}
            loading={isDownloadingReport}
            disabled={isDownloadingReport}
          />
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <ZBonScreenModal
        withCoins
        onClose={onCloseZBonModal}
        open={zBonModalOptions.open}
        date={zBonModalOptions.date}
      />
      <XBonScreenModal onClose={onCloseXBonModal} open={xBonModalOptions.open} />
      <EmployeeSummaryModal
        onClose={onCloseEmployeeSumaryModal}
        open={employeeSummaryModalOptions.open}
        date={employeeSummaryModalOptions.date}
      />
    </>
  );
};

export default DailySummaryModal;
