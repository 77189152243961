import alcohol from './alcohol.png';
import bagel from './bagel.png';
import baguette from './baguette.png';
import bier from './bier.png';
import blumen from './blumen.png';
import blumenstrauss from './blumenstrauss.png';
import broetchen from './broetchen.png';
import brot from './brot.png';
import bubbletea from './bubbletea.png';
import burger from './burger.png';
import champagner from './champagner.png';
import cognac from './cognac.png';
import croissant from './croissant.png';
import eier from './eier.png';
import eis from './eis.png';
import energydrink from './energydrink.png';
import fisch from './fisch.png';
import flaschen from './flaschen.png';
import frauenfriseur from './frauenfriseur.png';
import frozen from './frozen.png';
import gambas from './gambas.png';
import gebaeck from './gebaeck.png';
import geschenke from './geschenke.png';
import gesichtsbehandlung from './gesichtsbehandlung.png';
import grill from './grill.png';
import halal from './halal.png';
import hauptgericht from './hauptgericht.png';
import herrenfriseur from './herrenfriseur.png';
import hochzeitfriseur from './hochzeitfriseur.png';
import huhn from './huhn.png';
import hummer from './hummer.png';
import icedcoffee from './icedcoffee.png';
import kaffee from './kaffee.png';
import kaffeebohnen from './kaffeebohnen.png';
import kartoffel from './kartoffel.png';
import kids from './kids.png';
import kinderfriseur from './kinderfriseur.png';
import kuchen from './kuchen.png';
import lamm from './lamm.png';
import latte from './latte.png';
import limonade from './limonade.png';
import longdrink from './longdrink.png';
import massage from './massage.png';
import menu from './menu.png';
import microblading from './microblading.png';
import milchshake from './milchshake.png';
import modernshisha from './modernshisha.png';
import nachos from './nachos.png';
import nudeln from './nudeln.png';
import panini from './panini.png';
import pfannkuchen from './pfannkuchen.png';
import pizza from './pizza.png';
import pommes from './pommes.png';
import proteine from './proteine.png';
import quesadilla from './quesadilla.png';
import rabatt from './rabatt.png';
import rauchen from './rauchen.png';
import reis from './reis.png';
import rind from './rind.png';
import rosen from './rosen.png';
import salat from './salat.png';
import sandwich from './sandwich.png';
import schnitzel from './schnitzel.png';
import service from './service.png';
import shisha from './shisha.png';
import softdrink from './softdrink.png';
import sonstige from './sonstige.png';
import sosse from './sosse.png';
import spiesse from './spiesse.png';
import steak from './steak.png';
import stern from './stern.png';
import suppe from './suppe.png';
import sushi from './sushi.png';
import sweets from './sweets.png';
import tabak from './tabak.png';
import taco from './taco.png';
import tattoo from './tattoo.png';
import tee from './tee.png';
import teigtasche from './teigtasche.png';
import toast from './toast.png';
import vegan from './vegan.png';
import vegetarisch from './vegetarisch.png';
import vorspeisse from './vorspeisse.png';
import waffle from './waffle.png';
import wein from './wein.png';
import whiskey from './whiskey.png';
import wimpern from './wimpern.png';
import wings from './wings.png';
import wodka from './wodka.png';
import wodkaglas from './wodkaglas.png';
import wrap from './wrap.png';
import wurst from './wurst.png';
import zeitschrift from './zeitschrift.png';
import zeitung from './zeitung.png';
import zigarre from './zigarre.png';

export default [
  alcohol,
  bagel,
  baguette,
  bier,
  blumen,
  blumenstrauss,
  broetchen,
  brot,
  bubbletea,
  burger,
  champagner,
  cognac,
  croissant,
  eier,
  eis,
  energydrink,
  fisch,
  flaschen,
  frauenfriseur,
  frozen,
  gambas,
  gebaeck,
  geschenke,
  gesichtsbehandlung,
  grill,
  halal,
  hauptgericht,
  herrenfriseur,
  hochzeitfriseur,
  huhn,
  hummer,
  icedcoffee,
  kaffee,
  kaffeebohnen,
  kartoffel,
  kids,
  kinderfriseur,
  kuchen,
  lamm,
  latte,
  limonade,
  longdrink,
  massage,
  menu,
  microblading,
  milchshake,
  modernshisha,
  nachos,
  nudeln,
  panini,
  pfannkuchen,
  pizza,
  pommes,
  proteine,
  quesadilla,
  rabatt,
  rauchen,
  reis,
  rind,
  rosen,
  salat,
  sandwich,
  schnitzel,
  service,
  shisha,
  softdrink,
  sonstige,
  sosse,
  spiesse,
  steak,
  stern,
  suppe,
  sushi,
  sweets,
  tabak,
  taco,
  tattoo,
  tee,
  teigtasche,
  toast,
  vegan,
  vegetarisch,
  vorspeisse,
  waffle,
  wein,
  whiskey,
  wimpern,
  wings,
  wodka,
  wodkaglas,
  wrap,
  wurst,
  zeitschrift,
  zeitung,
  zigarre,
];
