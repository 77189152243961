/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */

import { getItemsPageSorted, getSearchedItems, getAllergens } from '../../../api/ItemAPI';
import { getAllCategoriesShopAll } from '../../../api/CategoryAPI';

/* eslint-disable no-unused-vars */
export const getItemsData = async (
  shopId,
  page,
  entity,
  direction,
  selectedColumn,
  category,
  token
) => {
  try {
    const res = await getItemsPageSorted(shopId, page, 10, entity, direction, category, token);
    if (res && res.success) {
      return {
        items: res.items,
        categories: res.categories,
        selectedColumn,
        sortDirection: direction,
        totalPages: res.totalPages,
        currentPage: page,
        isSearching: false,
        searchTerm: '',
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getSearchData = async (shopId, searchTerm, page, token) => {
  try {
    const res = await getSearchedItems(shopId, searchTerm, page, token);
    if (res && res.success) {
      return {
        items: res.results,
        selectedColumn: null,
        sortDirection: 'descending',
        totalPages: res.totalPages,
        currentPage: page,
        isSearching: true,
        searchTerm,
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getCategories = async (token) => {
  try {
    const res = await getAllCategoriesShopAll(token);
    if (res && res.success) {
      return res;
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getAllAllergens = async () => {
  try {
    const res = await getAllergens();
    if (res && res.success) {
      return res;
    }
    return null;
  } catch (_) {
    return null;
  }
};
