import React from 'react';

// BUTTON:
// {
//   text: i18n.t('owner'),
//   value: options.owner,
// }
const SelectionButtons = ({ selected, onSelect, buttons }) => {
  const buttonStyle = {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    padding: '7px 12px',
    border: 'none',
    height: '38px',
    flex: '1',
    cursor: 'pointer',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    minHeight: '36px',
    transition: 'background-color 0.2s, color 0.2s',
  };

  const activeColor = '#e6e3fa';
  const activeTextColor = '#7268cd';
  const inactiveTextColor = '#7268cdc2';

  return (
    <div
      style={{
        display: 'flex',
        border: '1px solid #d7d3f6',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    >
      {buttons.map((button, index) => (
        <button
          type="button"
          key={button.value}
          style={{
            ...buttonStyle,
            borderRight: index < buttons.length - 1 ? '1px solid #d7d3f6' : 'none',
            backgroundColor: selected === button.value ? activeColor : 'white',
            fontWeight: 700,
            color: selected === button.value ? activeTextColor : inactiveTextColor,
          }}
          onClick={() => {
            onSelect(button.value);
          }}
        >
          {button.text}
        </button>
      ))}
    </div>
  );
};

export default SelectionButtons;
