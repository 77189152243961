import { get, del, post, put } from './Fetch';

export const getAllCategories = async () => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories`;
  return get(url, {});
};

export const getAllCategoriesShopAll = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories/all`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getAllCategoriesOfShop = async (shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories/shop/${shopId}`;
  return get(url, {});
};

export const getAllCategoriesOfShopQr = async (shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories/qr/shop/${shopId}`;
  return get(url, {});
};

export const getAllCategoriesOfShopTerminal = async (shopId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories/terminal/shop/${shopId}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getAllCategoriesOfShopByShop = async (shopId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories/shop`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const deleteCategory = async (token, categoryId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories/${categoryId}`;
  const headers = { Authorization: token };
  return del(url, headers);
};

export const deleteCategoryByShop = async (categoryId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories/shop/${categoryId}`;
  const headers = { Authorization: token };
  return del(url, headers);
};

export const deleteCategoriesByShop = async (token, categoryIds) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories/shop/multiple/delete`;
  const body = { categoryIds };
  const headers = { Authorization: token };
  return del(url, headers, body);
};

export const createCategory = async (token, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories`;
  const body = data;
  const headers = { Authorization: token };
  return post(url, headers, body);
};

export const createCategoryByShop = async (data, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories/shop`;
  const body = data;
  const headers = { Authorization: token };
  return post(url, headers, body);
};

export const updateCategory = async (token, categoryId, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories/${categoryId}`;
  const body = data;
  const headers = { Authorization: token };
  return put(url, headers, body);
};

export const updateCategoryByShop = async (token, categoryId, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}categories/shop/${categoryId}`;
  const body = data;
  const headers = { Authorization: token };
  return put(url, headers, body);
};
