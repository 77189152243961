/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, Dropdown, Icon, Popup } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import { useStateValue } from '../../../core/context/StateProvider';
import './ItemsScreen.css';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import icons from './assets/ItemsAssets';
import {
  createCategoryByShop,
  deleteCategoryByShop,
  updateCategoryByShop,
} from '../../../api/CategoryAPI';

const ItemsCategoryModal = (props) => {
  const { category, onClose, open } = props;
  const [{ config, token, shop }] = useStateValue();
  const [data, setData] = useState({
    category: {
      name: '',
      image: '',
      color: '#ffffff',
      translations: [
        { lang: 'de', val: '' },
        { lang: 'en', val: '' },
      ],
    },
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open && category) {
      setData({ category });
    }
  }, [category, open]);

  useEffect(() => {
    if (
      data.category.name.length !== 0 &&
      data.category.image.length !== 0 &&
      (category == null ||
        (category != null &&
          (category.name !== data.category.name ||
            category.image !== data.category.image ||
            category.color !== data.category.color ||
            !isEqual(category.translations, data.category.translations))))
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [data.category]);

  const closeModal = (refreshNeeded) => {
    setData({
      category: {
        name: '',
        image: '',
        color: '#ffffff',
        translations: [
          { lang: 'de', val: '' },
          { lang: 'en', val: '' },
        ],
      },
    });
    setErrorWarningModalOptions({ open: false, message: '' });
    setIsSaveDisabled(true);
    setIsLoading(false);
    closeWarningModal();
    setIsDeleteLoading(false);
    onClose(refreshNeeded);
  };

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const imageToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image to base64:', error);
      setErrorWarningModalOptions({ open: true, message: i18n.t('itemsImageError') });
      throw error;
    }
  };

  const onDelete = async () => {
    setWarningModalOptions({
      open: true,
      title: i18n.t('itemsCategoryDeleteWarningTitle'),
      message: i18n.t('itemsCategoryDeleteWarningMessage'),
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        setIsDeleteLoading(true);
        const res = await deleteCategoryByShop(category._id, token);
        if (res && res.success) {
          closeModal(true);
        } else {
          if (res && res.error && res.error.includes('Item with this category exists')) {
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('itemCategoryDeleteError2'),
            });
          } else {
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('itemCategoryDeleteError'),
            });
          }
          setIsDeleteLoading(false);
          closeWarningModal();
        }
      },
      no: true,
      onNo: () => {
        setIsDeleteLoading(false);
        closeWarningModal();
      },
    });
  };

  const onSave = async () => {
    let { image } = data.category;
    if (category == null) {
      try {
        image = await imageToBase64(data.category.image);
      } catch (error) {
        setErrorWarningModalOptions({ open: true, message: i18n.t('itemsImageError') });
        return;
      }
    }
    if (
      data.category.translations[0].val.length === 0 ||
      data.category.translations[1].val.length === 0
    ) {
      let newTranslation1 = { lang: 'de', val: data.category.translations[0].val };
      let newTranslation2 = { lang: 'en', val: data.category.translations[1].val };
      if (data.category.translations[0].val.length === 0) {
        newTranslation1 = { lang: 'de', val: data.category.name };
      }
      if (data.category.translations[1].val.length === 0) {
        newTranslation2 = { lang: 'en', val: data.category.name };
      }
      setData({
        category: {
          ...data.category,
          translations: [newTranslation1, newTranslation2],
        },
      });
    }
    setIsLoading(true);
    let change = { shopId: shop._id };
    if (category) {
      if (category.image !== data.category.image) {
        change.image = image;
      }
      if (category.color !== data.category.color) {
        change.color = data.category.color;
      }
      if (!isEqual(category.translations, data.category.translations)) {
        change.translations = data.category.translations;
      }
    } else {
      change = { ...change, ...data.category };
      change.shopId = shop._id;
    }
    if (change._id) {
      change._id = null;
      delete change._id;
    }
    try {
      const res = category
        ? await updateCategoryByShop(token, category._id, change)
        : await createCategoryByShop(change, token);
      setIsLoading(false);
      if (res && res.success) {
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: `${i18n.t('itemCategoryCreateError')}`,
        });
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: `${i18n.t('itemCategoryCreateError')}`,
      });
      setIsLoading(false);
    }
  };

  const parseColors = () => (
    <>
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          gap: 6,
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginBottom: 6,
        }}
      >
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: 'white', border: '1px solid rgb(171, 171, 171)' }}
            onClick={() => setData({ category: { ...data.category, color: '#ffffff' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#CDCDCD' }}
            onClick={() => setData({ category: { ...data.category, color: '#CDCDCD' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#5C5D67' }}
            onClick={() => setData({ category: { ...data.category, color: '#5C5D67' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#141301' }}
            onClick={() => setData({ category: { ...data.category, color: '#141301' } })}
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          gap: 6,
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginBottom: 6,
        }}
      >
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#7268cd' }}
            onClick={() => setData({ category: { ...data.category, color: '#7268cd' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#A379C9' }}
            onClick={() => setData({ category: { ...data.category, color: '#A379C9' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#B744B8' }}
            onClick={() => setData({ category: { ...data.category, color: '#B744B8' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#F283B6' }}
            onClick={() => setData({ category: { ...data.category, color: '#F283B6' } })}
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          gap: 6,
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginBottom: 6,
        }}
      >
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#ACDDE7' }}
            onClick={() => setData({ category: { ...data.category, color: '#ACDDE7' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#9AD5CA' }}
            onClick={() => setData({ category: { ...data.category, color: '#9AD5CA' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#00A8E8' }}
            onClick={() => setData({ category: { ...data.category, color: '#00A8E8' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#26547C' }}
            onClick={() => setData({ category: { ...data.category, color: '#26547C' } })}
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          gap: 6,
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginBottom: 6,
        }}
      >
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#D0D6B3' }}
            onClick={() => setData({ category: { ...data.category, color: '#D0D6B3' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#A5C882' }}
            onClick={() => setData({ category: { ...data.category, color: '#A5C882' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#7DCD85' }}
            onClick={() => setData({ category: { ...data.category, color: '#7DCD85' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#57886C' }}
            onClick={() => setData({ category: { ...data.category, color: '#57886C' } })}
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          gap: 6,
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginBottom: 6,
        }}
      >
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#FFDC5E' }}
            onClick={() => setData({ category: { ...data.category, color: '#FFDC5E' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#FF7F11' }}
            onClick={() => setData({ category: { ...data.category, color: '#FF7F11' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#DB2B39' }}
            onClick={() => setData({ category: { ...data.category, color: '#DB2B39' } })}
          />
        </div>
        <div className="color-picker-background">
          <div
            className="color-picker-foreground"
            style={{ backgroundColor: '#A3333D' }}
            onClick={() => setData({ category: { ...data.category, color: '#A3333D' } })}
          />
        </div>
      </div>
    </>
  );

  const parseImageSection = () => (
    <div
      style={{
        minWidth: 200,
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemsIcon')}*</p>
        <Popup
          style={{
            backgroundColor: '#7268cd',
            borderRadius: 12,
            maxHeight: 200,
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            width: 200,
          }}
          className="no-scrollbar"
          on={['click']}
          // eslint-disable-next-line prettier/prettier
          trigger={(
            <div
              style={{
                height: 176,
                border: data.category.image.length > 0 ? 'none' : '1px dashed #dedede',
                borderRadius: 12,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 16,
                color: '#767676',
                fontWeight: 500,
                backgroundColor:
                  data.category.color !== '#ffffff' ? data.category.color : '#f5f5f9',
                cursor: 'pointer',
                overflow: 'hidden',
              }}
            >
              {data.category.image.length > 0 ? (
                <>
                  <img
                    alt="Category"
                    src={
                      category && category.image === data.category.image
                        ? `${config.cloudinaryUrlPrefix}${data.category.image}`
                        : data.category.image
                    }
                    id="items-modal-image"
                    style={{ objectFit: 'none', height: 176, width: '100%' }}
                  />
                </>
              ) : (
                <>
                  <Icon name="image outline" size="big" color="grey" />
                  <p>{i18n.t('itemsChooseSymbol')}</p>
                </>
              )}
            </div>
            // eslint-disable-next-line prettier/prettier
          )}
          // eslint-disable-next-line prettier/prettier
          content={(
            <div
              style={{
                backgroundColor: '#7268cd',
                display: 'flex',
                gap: 10,
                width: '200px',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {icons.map((icon, index) => (
                <img
                  src={icon}
                  height={26}
                  alt={`icon-${index}`}
                  style={{ padding: 2, cursor: 'pointer' }}
                  className="category-icons-items"
                  onClick={() => {
                    setData({ category: { ...data.category, image: icon } });
                  }}
                />
              ))}
            </div>
            // eslint-disable-next-line prettier/prettier
          )}
          position="bottom center"
          popper={<div style={{ filter: 'none' }} />}
        />
      </div>
      <div className="items-modal-group-section" style={{ height: 126 }}>
        <p className="items-modal-section-title">{i18n.t('itemsFarbe')}</p>
        <Popup
          style={{
            borderRadius: 12,
            maxHeight: 200,
            overflowY: 'scroll',
            scrollbarWidth: 'none',
          }}
          className="no-scrollbar"
          on={['click']}
          // eslint-disable-next-line prettier/prettier
          trigger={(
            <div
              style={{
                borderRadius: 10,
                backgroundColor: data.category.color,
                border: data.category.color !== '#ffffff' ? 'none' : '1px dashed #dedede',
                padding: 24,
                height: 60,
                cursor: 'pointer',
              }}
              onChange={(e) => {
                setData({ category: { ...data.category, color: e.target.value } });
              }}
            />
            // eslint-disable-next-line prettier/prettier
          )}
          // eslint-disable-next-line prettier/prettier
          content={(
            <div>{parseColors()}</div>
            // eslint-disable-next-line prettier/prettier
          )}
          position="bottom center"
          popper={<div style={{ filter: 'none' }} />}
        />
      </div>
    </div>
  );

  const parseGeneralSection = () => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('generalSettingsTitle')}</p>
        <div style={{ width: '100%' }}>
          <p style={{ fontWeight: 600 }}>{i18n.t('itemsTitle')}*</p>
          <input
            className="item-modal-input"
            placeholder={i18n.t('itemsTitle')}
            value={data.category.name}
            defaultValue={category ? category.name : null}
            readOnly={category ? true : false}
            onChange={(e) => setData({ category: { ...data.category, name: e.target.value } })}
          />
        </div>
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemsTranslations')}</p>
        <div style={{ width: '100%', marginBottom: 14 }}>
          <p style={{ fontWeight: 600 }}>{i18n.t('itemsGerman')}</p>
          <input
            className="item-modal-input"
            placeholder={i18n.t('itemsGerman')}
            value={data.category.translations[0].val}
            onChange={(e) => {
              const newTranslation = { lang: 'de', val: e.target.value };
              setData({
                category: {
                  ...data.category,
                  translations: [newTranslation, data.category.translations[1]],
                },
              });
            }}
          />
        </div>
        <div style={{ width: '100%' }}>
          <p style={{ fontWeight: 600 }}>{i18n.t('itemsEnglish')}</p>
          <input
            className="item-modal-input"
            placeholder={i18n.t('itemsEnglish')}
            value={data.category.translations[1].val}
            onChange={(e) => {
              const newTranslation = { lang: 'en', val: e.target.value };
              setData({
                category: {
                  ...data.category,
                  translations: [data.category.translations[0], newTranslation],
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <AnimatedModal
        open={open}
        dimmer="blurring"
        size="small"
        closeOnEscape
        closeOnDimmerClick
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{
            wordBreak: 'break-all',
            borderBottom: 'none',
            backgroundColor: '#f5f5f9',
            paddingBottom: 4,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ width: '100%', marginBottom: 0 }}>
              {data.category.name || i18n.t('itemsNewCategoryTitle')}
            </p>
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => closeModal(false)}
              floated="right"
              className="items-close-btn"
              circular
            />
          </div>
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{ backgroundColor: '#f5f5f9', scrollbarWidth: 'none', paddingBottom: 0 }}
          className="no-scrollbar"
        >
          <div className="items-modal-section-group">
            {parseImageSection()}
            {parseGeneralSection()}
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9', paddingTop: 12 }}>
          <Button
            color="teal"
            content={i18n.t('bookingModalEditSave')}
            onClick={() => onSave()}
            disabled={isSaveDisabled || isLoading || isDeleteLoading}
            loading={isLoading}
          />
          {category ? (
            <Button
              floated="left"
              color="red"
              content={i18n.t('itemsDelete')}
              onClick={() => onDelete()}
              disabled={category == null || isLoading || isDeleteLoading}
              loading={isDeleteLoading}
            />
          ) : (
            <></>
          )}
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
      />
    </>
  );
};

export default ItemsCategoryModal;
