/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, Dropdown, Table } from 'semantic-ui-react';
import useScanDetection from 'use-scan-detection';
import { getCategories } from './ItemsScreenData';
import { useStateValue } from '../../../core/context/StateProvider';
import './ItemsScreen.css';
import { createMultiItems, getOFFProduct } from '../../../api/ItemAPI';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import PriceInput from '../../../components/general/PriceInput/PriceInput';
import scanIcon from '../Bookings/icons/scanProduct.png';
import addIcon from '../Bookings/icons/addProduct.png';

const ItemsMultiModal = (props) => {
  const { onClose, open } = props;
  const [{ token, shop }] = useStateValue();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState({ categories: [] });
  const [items, setItems] = useState([]);
  const [item, setItem] = useState({
    title: '',
    price: 0,
    categoryName: '',
    categoryId: '',
    inHouseTaxRate: 0.19,
    takeAwayTaxRate: 0.19,
    barcode: '',
  });
  const [isLoadingInputs, setIsLoadingInputs] = useState(false);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    if (items.length > 0 && isSaveDisabled === true) {
      setIsSaveDisabled(false);
    }
    if (items.length === 0 && isSaveDisabled === false) {
      setIsSaveDisabled(true);
    }
  }, [items]);

  const closeModal = (refreshNeeded) => {
    setIsLoading(false);
    setItems([]);
    setItem({
      title: '',
      price: 0,
      categoryName: '',
      categoryId: '',
      inHouseTaxRate: 0.19,
      takeAwayTaxRate: 0.19,
      barcode: '',
    });
    setIsLoadingInputs(false);
    setIsSaveDisabled(true);
    onClose(refreshNeeded);
  };

  useScanDetection({
    minLength: 8,
    onComplete: async (barcode) => {
      setIsLoadingInputs(true);
      try {
        const res = await getOFFProduct(barcode, token);
        setIsLoadingInputs(false);
        if (res && res.success) {
          const newItem = { ...item };
          const resItem = res.item;
          if (resItem.barcode) {
            newItem.barcode = resItem.barcode;
          }
          if (resItem.title && resItem.title.length > 0) {
            newItem.title = resItem.title;
          }
          if (
            resItem.categoryName &&
            resItem.categoryName.length > 0 &&
            resItem.categoryId &&
            resItem.categoryId.length > 0
          ) {
            newItem.categoryName = resItem.categoryName;
            newItem.categoryId = resItem.categoryId;
          }
          setItem({ ...newItem });
        }
      } catch (error) {
        setIsLoadingInputs(false);
      }
    },
  });

  const onSave = async () => {
    try {
      setIsLoading(true);
      const res = await createMultiItems(items, token);
      setIsLoading(false);
      if (res && res.success) {
        closeModal(true);
      } else {
        setErrorWarningModalOptions({ open: true, message: i18n.t('multiItemsCreateError') });
      }
    } catch (error) {
      setIsLoading(false);
      setErrorWarningModalOptions({ open: true, message: i18n.t('multiItemsCreateError') });
    }
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const getDropdownData = async () => {
    const cat = await getCategories(token);
    if (cat && cat.success) {
      setDropDownData({
        categories: cat.categories,
      });
    } else {
      setDropDownData({ categories: [] });
    }
  };

  const getCategoryTranslationForName = (categoryName) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (dropDownData.categories != null) {
      const category = dropDownData.categories.find((category) => category.name === categoryName);
      if (category && lang && category.translations && category.translations.length > 0) {
        const name = category.translations.find((translation) => translation.lang === lang);
        if (name) {
          return name.val;
        }
        const englishName = category.translations.find((translation) => translation.lang === 'en');
        if (englishName) {
          return englishName.val;
        }
        return category.name;
      }
    }
    return categoryName;
  };

  const getOriginalCategoryFromName = (categoryName) => {
    if (dropDownData.categories !== null) {
      const category = dropDownData.categories.find((category) =>
        category.translations.find((translation) => translation.val === categoryName)
      );
      if (category) {
        return category.name;
      }
      return categoryName;
    }
    return categoryName;
  };

  const onDropdownChange = (value) => {
    if (value) {
      const category = dropDownData.categories.find(
        (category) => category.name === getOriginalCategoryFromName(value)
      );
      setItem({ ...item, categoryName: category.name, categoryId: category._id });
    }
  };

  const getCategoryTranslation = (category) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (
      category.name !== i18n.t('appAllCategories') &&
      lang &&
      category.translations &&
      category.translations.length > 0
    ) {
      const name = category.translations.find((translation) => translation.lang === lang);
      if (name) {
        return name.val;
      }
      const englishName = category.translations.find((translation) => translation.lang === 'en');
      if (englishName) {
        return englishName.val;
      }
      return category.name;
    }
    return category.name;
  };

  const dropdownOptions = (categories) => {
    if (categories) {
      const options = [];
      const shopDefinedCategories = categories
        .filter((cat) => cat.shopId != null)
        .map((category) => getCategoryTranslation(category))
        .sort();
      const generalCategories = categories
        .filter((cat) => cat.shopId == null)
        .map((category) => getCategoryTranslation(category))
        .sort();
      if (shopDefinedCategories.length > 0) {
        options.push({
          key: 'shopDefinedCategories',
          text: (
            <div style={{ color: '#7268cd', fontWeight: 700, opacity: 1, pointerEvents: 'none' }}>
              {i18n.t('shopDefinedCategories')}
            </div>
          ),
          value: 'shopDefinedCategories',
          disabled: true,
        });
        shopDefinedCategories.forEach((category, index) => {
          const object = {};
          object.key = `${category}-${index}`;
          object.value = category;
          object.text = category;
          options.push(object);
        });
        options.push({
          key: 'generalDefinedCategories',
          text: (
            <div style={{ color: '#7268cd', fontWeight: 700, opacity: 1, pointerEvents: 'none' }}>
              {i18n.t('generalDefinedCategories')}
            </div>
          ),
          value: 'generalDefinedCategories',
          disabled: true,
        });
      }
      generalCategories.forEach((category, index) => {
        const object = {};
        object.key = `${category}-${index}`;
        object.value = category;
        object.text = category;
        options.push(object);
      });
      return options;
    }
    return [];
  };

  return (
    <>
      <AnimatedModal open={open} dimmer="blurring" size="large" closeOnEscape closeOnDimmerClick>
        <Modal.Header
          style={{
            wordBreak: 'break-all',
            borderBottom: 'none',
            backgroundColor: '#f5f5f9',
            paddingBottom: 4,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ width: '100%', marginBottom: 0 }}>{i18n.t('itemsMultiTitle')}</p>
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => closeModal(false)}
              floated="right"
              className="items-close-btn"
              circular
              style={{ marginRight: '0px !important' }}
            />
          </div>
        </Modal.Header>
        <Modal.Content
          style={{ backgroundColor: '#f5f5f9', scrollbarWidth: 'none', paddingBottom: 4 }}
          className="no-scrollbar"
        >
          <div
            className="bookings-table-container-discount"
            style={{ maxHeight: 'calc(100vh - 300px)', minHeight: 400 }}
          >
            <Table
              celled
              padded
              style={{
                border: 'none',
                borderTopLeftRadius: 40,
                borderTopRightRadius: 40,
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                    {i18n.t('itemsTitle')}
                  </Table.HeaderCell>
                  <Table.HeaderCell width="2" style={{ backgroundColor: 'white' }}>
                    {i18n.t('itemsPrice')}
                  </Table.HeaderCell>
                  <Table.HeaderCell width="4" style={{ backgroundColor: 'white' }}>
                    {i18n.t('itemsCategory')}
                  </Table.HeaderCell>
                  <Table.HeaderCell width="1" style={{ backgroundColor: 'white' }}>
                    {i18n.t('taxRate')}
                  </Table.HeaderCell>
                  <Table.HeaderCell width="3" style={{ backgroundColor: 'white' }}>
                    Barcode
                  </Table.HeaderCell>
                  <Table.HeaderCell width="1" style={{ backgroundColor: 'white' }} />
                </Table.Row>
              </Table.Header>
              <Table.Header>
                <Table.Row style={{ backgroundColor: 'white' }}>
                  <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                    <div className="input-wrapper">
                      <input
                        className="item-modal-input"
                        placeholder={i18n.t('itemsTitle')}
                        value={item.title}
                        onChange={(e) => {
                          setItem({ ...item, title: e.target.value });
                        }}
                      />
                      {isLoadingInputs && <div className="loader" />}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                    <div className="input-wrapper">
                      <PriceInput
                        price={item.price}
                        onChange={(value) => setItem({ ...item, price: value })}
                      />
                      {isLoadingInputs && <div className="loader" />}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                    <Dropdown
                      selection
                      placeholder={i18n.t('itemsCategories')}
                      fluid
                      value={getCategoryTranslationForName(item.categoryName)}
                      onChange={(_, data) => onDropdownChange(data.value)}
                      options={dropdownOptions(dropDownData.categories)}
                      loading={dropDownData.categories.length === 0 || isLoadingInputs}
                      className="items-modal-dropdown-new"
                      id="items-dropdown"
                      style={{ marginTop: 0, fontWeight: 400 }}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                    <Button.Group
                      style={{
                        fontFamily: 'Montserrat',
                        border: '1px solid #d7d3f6',
                        borderRadius: 6,
                        height: 28,
                        overflow: 'hidden',
                      }}
                    >
                      <Button
                        className="item-modal-buttongroup-button"
                        style={{
                          borderRight: '1px solid #d7d3f6',
                          backgroundColor: item.inHouseTaxRate === 0.07 ? 'white' : '#e6e3fa',
                          color: item.inHouseTaxRate === 0.07 ? '#7268cdc2' : '#7268cd',
                        }}
                        onClick={() => {
                          setItem({
                            ...item,
                            inHouseTaxRate: 0.19,
                            takeAwayTaxRate: 0.19,
                          });
                        }}
                      >
                        19%
                      </Button>
                      <Button
                        className="item-modal-buttongroup-button"
                        style={{
                          backgroundColor: item.inHouseTaxRate === 0.07 ? '#e6e3fa' : 'white',
                          color: item.inHouseTaxRate === 0.07 ? '#7268cd' : '#7268cdc2',
                        }}
                        onClick={() => {
                          setItem({
                            ...item,
                            inHouseTaxRate: 0.07,
                            takeAwayTaxRate: 0.07,
                          });
                        }}
                      >
                        7%
                      </Button>
                    </Button.Group>
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                    <div className="input-wrapper">
                      <input
                        className="item-modal-input"
                        placeholder="Barcode"
                        value={item.barcode}
                        onChange={(e) => {
                          setItem({ ...item, barcode: e.target.value });
                        }}
                      />
                      {isLoadingInputs && <div className="loader" />}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                    <button
                      type="button"
                      className="item-modal-button-add"
                      disabled={
                        item.title == null ||
                        item.title.length === 0 ||
                        item.categoryName == null ||
                        item.categoryName.length === 0
                      }
                      onClick={() => {
                        setItems([...items, item]);
                        setItem({
                          title: '',
                          price: 0,
                          categoryName: '',
                          categoryId: '',
                          inHouseTaxRate: 0.19,
                          takeAwayTaxRate: 0.19,
                          barcode: '',
                        });
                      }}
                    >
                      +
                    </button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {items.length > 0 ? (
                  <>
                    {items.map((nItem, index) => (
                      <Fragment key={`${nItem.title}-${index}`}>
                        <Table.Row style={{ backgroundColor: 'white' }}>
                          <Table.Cell>
                            <input
                              className="item-modal-input"
                              defaultValue={nItem.title}
                              readOnly
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <PriceInput readOnly price={nItem.price} />
                          </Table.Cell>
                          <Table.Cell>
                            <input
                              className="item-modal-input"
                              defaultValue={nItem.categoryName}
                              readOnly
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Button.Group
                              style={{
                                fontFamily: 'Montserrat',
                                border: '1px solid #d7d3f6',
                                borderRadius: 6,
                                height: 28,
                                overflow: 'hidden',
                                width: '100%',
                              }}
                            >
                              <Button
                                className="item-modal-buttongroup-button"
                                style={{
                                  borderRight: '1px solid #d7d3f6',
                                  backgroundColor: '#e6e3fa',
                                  color: '#7268cd',
                                }}
                                onClick={() => {
                                  setItem({
                                    ...item,
                                    inHouseTaxRate: 0.19,
                                    takeAwayTaxRate: 0.19,
                                  });
                                }}
                              >
                                {nItem.inHouseTaxRate === 0.19 ? '19%' : '7%'}
                              </Button>
                            </Button.Group>
                          </Table.Cell>
                          <Table.Cell>
                            <input
                              className="item-modal-input"
                              defaultValue={nItem.barcode || '-'}
                              readOnly
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <button
                              type="button"
                              className="item-modal-button-remove"
                              onClick={() => {
                                setItems([...items.filter((_, i) => i !== index)]);
                              }}
                            >
                              ×
                            </button>
                          </Table.Cell>
                        </Table.Row>
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    <Table.Row>
                      <Table.Cell
                        colSpan="100%"
                        textAlign="center"
                        style={{
                          height: '100px',
                          borderBottomRightRadius: 20,
                          borderBottomLeftRadius: 20,
                          padding: 40,
                          backgroundColor: '#f5f5f9',
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              padding: 20,
                              paddingTop: 12,
                              paddingBottom: 12,
                              borderRadius: 14,
                              color: '#888',
                            }}
                          >
                            <p style={{ fontWeight: 500, fontSize: 16 }}>
                              {i18n.t('itemsMultiAddOrScan')}
                            </p>
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                                gap: 20,
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  border: '2px solid #e5e1fb',
                                  borderRadius: 30,
                                  width: 60,
                                  height: 60,
                                  padding: 10,
                                }}
                              >
                                <img src={addIcon} alt="Scan" height={34} />
                              </div>
                              <div
                                style={{
                                  width: 1,
                                  height: 36,
                                  borderRight: '1px solid #7268cd',
                                }}
                              />
                              <div
                                style={{
                                  border: '2px solid #e5e1fb',
                                  borderRadius: 30,
                                  width: 60,
                                  height: 60,
                                  padding: 10,
                                }}
                              >
                                <img src={scanIcon} alt="Scan" height={34} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </>
                )}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9', paddingTop: 12 }}>
          <Button
            color="teal"
            content={i18n.t('bookingModalEditSave')}
            onClick={() => onSave()}
            disabled={isSaveDisabled || isLoading}
            loading={isLoading}
          />
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default ItemsMultiModal;
