/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button } from 'semantic-ui-react';
import { updateShop } from '../../../api/ShopAPI';
import StyledInput from '../../../components/general/Input/Input';
import { useStateValue } from '../../../core/context/StateProvider';
import './SettingsScreen.css';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const AddressModal = (props) => {
  const { onClose, open } = props;
  const [{ token, shop }] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [data, setData] = useState({
    address: {
      street: '',
      zip: '',
      city: '',
      country: '',
    },
    phoneNumber: '',
  });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (open) {
      setData({
        address: {
          street: shop.address.street,
          zip: shop.address.zip,
          city: shop.address.city,
          country: shop.address.country,
        },
        phoneNumber: shop.phoneNumber || '',
      });
    }
  }, [open]);

  useEffect(() => {
    if (
      data.address.city.trim().length !== 0 &&
      data.address.zip.trim().length !== 0 &&
      data.address.street.trim().length !== 0 &&
      data.phoneNumber.trim().length !== 0 &&
      (shop.address.street !== data.address.street ||
        shop.address.zip !== data.address.zip ||
        shop.address.city !== data.address.city ||
        shop.phoneNumber !== data.phoneNumber)
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [data]);

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onSave = async () => {
    setIsLoading(true);
    const res = await updateShop(token, data);
    setIsLoading(false);
    if (res && res.success) {
      closeModal(true);
    } else {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('addressUpdateError'),
      });
    }
  };

  const closeModal = (refreshNeeded) => {
    setData({
      address: {
        street: '',
        zip: '',
        city: '',
        country: '',
      },
      phoneNumber: '',
    });
    setIsSaveDisabled(true);
    onClose(refreshNeeded);
  };

  return (
    <>
      <AnimatedModal
        open={open}
        dimmer="blurring"
        size="tiny"
        closeOnEscape
        closeOnDimmerClick
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{ borderBottom: 'none', backgroundColor: '#f5f5f9', fontFamily: 'Montserrat' }}
        >
          {i18n.t('shopAddressTitle')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content style={{ backgroundColor: '#f5f5f9', paddingTop: 4, paddingBottom: 4 }}>
          <div className="address-fields">
            <p style={{ fontWeight: 600, fontSize: 14, marginBottom: 14 }}>
              {i18n.t('shopStreet')}
            </p>
            <input
              style={{ backgroundColor: 'white', marginBottom: 20 }}
              className="item-modal-input"
              placeholder={i18n.t('shopStreet')}
              value={data.address.street}
              onChange={(e) => {
                setData({
                  address: { ...data.address, street: e.target.value },
                  phoneNumber: data.phoneNumber,
                });
              }}
            />
            <div style={{ display: 'flex', gap: 8, width: '100%', marginBottom: 20 }}>
              <div style={{ width: 110 }}>
                <p style={{ fontWeight: 600, fontSize: 14, marginBottom: 14 }}>
                  {i18n.t('shopZip')}
                </p>
                <input
                  style={{ backgroundColor: 'white' }}
                  className="item-modal-input"
                  placeholder={i18n.t('shopZip')}
                  value={data.address.zip}
                  type="number"
                  onKeyDown={(e) => {
                    if (
                      e.key === '.' ||
                      e.key === ',' ||
                      e.key === '-' ||
                      e.key === '+' ||
                      e.key === 'e'
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const trimmedValue =
                      e.target.value != null ? e.target.value.trim() : e.target.value;
                    setData({
                      address: { ...data.address, zip: trimmedValue },
                      phoneNumber: data.phoneNumber,
                    });
                  }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <p style={{ fontWeight: 600, fontSize: 14, marginBottom: 14 }}>
                  {i18n.t('shopCity')}
                </p>
                <input
                  style={{ backgroundColor: 'white' }}
                  className="item-modal-input"
                  placeholder={i18n.t('shopCity')}
                  value={data.address.city}
                  onChange={(e) => {
                    setData({
                      address: { ...data.address, city: e.target.value },
                      phoneNumber: data.phoneNumber,
                    });
                  }}
                />
              </div>
            </div>
            <p style={{ fontWeight: 600, fontSize: 14, marginBottom: 14 }}>
              {i18n.t('ownerPhone')}
            </p>
            <input
              style={{ backgroundColor: 'white', marginBottom: 16 }}
              className="item-modal-input"
              placeholder={i18n.t('ownerPhone')}
              value={data.phoneNumber}
              type="tel"
              onChange={(e) => {
                setData({ address: data.address, phoneNumber: e.target.value });
              }}
            />
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          <Button
            loading={isLoading}
            content={i18n.t('shopEditSave')}
            disabled={isSaveDisabled}
            floated="right"
            onClick={onSave}
            color="teal"
          />
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default AddressModal;
