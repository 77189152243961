/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import i18n from '../../../i18n/config';

const PriceInput = (props) => {
  const { onChange, price, readOnly } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const [isInitialFocus, setIsInitialFocus] = useState(true);

  const transform = (value) => {
    const numericValue = Number(value);
    if (numericValue === 0) return '0,00';

    return numericValue.toLocaleString('de-DE', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      useGrouping: false,
    });
  };

  useEffect(() => {
    if (!isFocused) {
      setDisplayValue(transform(price / 100));
      setIsInitialFocus(true);
    }
  }, [price, isFocused]);

  const handleFocus = () => {
    setIsFocused(true);
    if (isInitialFocus && !readOnly) {
      setDisplayValue('');
      setIsInitialFocus(false);
    }
  };

  const handleValueChange = (value) => {
    if (!readOnly) {
      setDisplayValue(value || '');
      if (value) {
        const val = value.replace(',', '.') * 100;
        onChange(val);
      } else {
        onChange(0);
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f5f5f9',
        border: isFocused ? '1px solid #7268cd' : '1px solid #dedede',
        borderRadius: 10,
        height: 40,
      }}
    >
      <p
        style={{
          fontSize: 15,
          fontWeight: 600,
          textAlign: 'right',
          paddingLeft: 10,
          marginBottom: 0,
        }}
      >
        €
      </p>
      <CurrencyInput
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        style={{ paddingLeft: 6, fontSize: 15 }}
        readOnly={readOnly}
        className="item-modal-input-price"
        placeholder={i18n.t('itemsPrice')}
        disableGroupSeparators
        intlConfig={{ locale: 'de-DE' }}
        value={displayValue}
        onBlur={() => {
          setIsFocused(false);
        }}
        onFocus={handleFocus}
        onValueChange={handleValueChange}
      />
    </div>
  );
};

export default PriceInput;
