/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import { Button, Grid, Table, Loader, Confirm, Popup } from 'semantic-ui-react';
import moment from 'moment';
import 'moment/locale/de';
import { ChromePicker } from 'react-color';
import { useMediaQuery } from '@react-hook/media-query';
import { getAllEmployees } from '../../../api/UserAPI';
import { useStateValue } from '../../../core/context/StateProvider';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import './SettingsScreen.css';
import AddressModal from './SettingsAddressModal';
import { getMe } from '../../../api/AppInfoAPI';
import OpeningHoursModal from './SettingsOpeningHoursModal';
import Toggle from '../../../components/general/Toggle/Toggle';
import { updateShop } from '../../../api/ShopAPI';
import EmployeesModal from './SettingsEmployeeModal';
import PrintersModal from './SettingsPrinterModal';
import {
  createStripeAccount,
  getIfShopHasStripeAccount,
  getStripeAccount,
  getStripeAccountLink,
  updateAllowedPaymentMethods,
} from '../../../api/PaymentAPI';
import TerminalReaderModal from './SettingsTerminalReaderModal';
import { getAllCategoriesOfShop } from '../../../api/CategoryAPI';
import TipSelectionButtons from './TipSelectionButtons';

const SettingsScreen = () => {
  const [{ token, shop }, dispatch] = useStateValue();
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [softRefreshNeeded, setSoftRefreshNeed] = useState(false);
  const [isShopOpen, setIsShopOpen] = useState(shop ? shop.isOpen : false);
  const [allowAutoPrint, setAllowAutoPrint] = useState(shop ? shop.autoPrint : false);
  const [allowAutoPrintCustomer, setAllowAutoPrintCustomer] = useState(
    shop ? shop.autoPrintCustomer : false
  );
  const [allowCashBook, setAllowCashBook] = useState(shop ? shop.allowCashBook : false);
  const [allowDiscounts, setAllowDiscounts] = useState(shop ? shop.allowDiscounts : false);
  const [allowPinRequired, setAllowPinRequired] = useState(shop ? shop.isPinRequired : false);
  const [allowTakeAwayTable, setAllowTakeAwayTable] = useState(
    shop ? shop.isTakeAwayTableAllowed : false
  );
  const [allowDiverseProducts, setAllowDiverseProducts] = useState(
    shop ? shop.allowDiverseProducts : false
  );
  const [allowInHouseTable, setAllowInHouseTable] = useState(
    shop ? shop.isInHouseTableAllowed : false
  );
  const [allowBarcodeScanner, setAllowBarcodeScanner] = useState(
    shop ? shop.hasBarcodeScanner : false
  );
  const tipOptions = {
    off: 'off',
    employee: 'employee',
    owner: 'owner',
  };
  const [selectedTipOption, setSelectedTipOption] = useState(
    shop ? shop.tipOption : tipOptions.off
  );
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isCreditCardChecked, setIsCreditCardChecked] = useState(false);
  const [isDarkModeChecked, setIsDarkModeChecked] = useState(shop ? shop.darkMode : false);
  const [isCashChecked, setIsCashChecked] = useState(false);
  const [hasStripeAccount, setHasStripeAccount] = useState(null);
  const [accountLink, setAccountLink] = useState(null);
  const [isWarning, setIsWarning] = useState(false);
  const [color, setColor] = useState(shop ? shop.color : '#ffffff');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [allowPrintPerCategory, setAllowPrintPerCategory] = useState(
    shop ? shop.printPerCategory : false
  );
  const [categories, setCategories] = useState([]);
  const [employeeTableData, setEmployeeTableData] = useState({
    employees: [],
    selectedColumn: null,
    sortDirection: 'descending',
    isSearching: false,
    searchTerm: '',
  });
  const [errorModalOptions, setErrorModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });
  const [addressModalOptions, setAddressModalOptions] = useState({ open: false });
  const [employeeModalOptions, setEmployeeModalOptions] = useState({ open: false, employee: null });
  const [openingHoursModalOptions, setOpeningHoursModalOptions] = useState({ open: false });
  const [printerModalOptions, setPrinterModalOptions] = useState({ open: false, printer: null });
  const [terminalReaderModalOptions, setTerminalReaderModalOptions] = useState({
    open: false,
    terminal: null,
  });

  const matches = useMediaQuery('only screen and (min-width: 766px)');

  useEffect(() => {
    if (employeeTableData) {
      if (!employeeTableData.employees.length && !employeeTableData.isSearching) {
        getEmployees();
      }
    }
    if (employeeTableData == null && !refreshRequired) {
      showErrorWithOk(i18n.t('settingsEmployeesLoadError'));
      setRefreshRequired(true);
    }
    if (softRefreshNeeded) {
      getShopData();
    }
    if (hasStripeAccount === null && !refreshRequired) {
      loadData();
    }
  });

  useEffect(() => {
    if (!refreshRequired) {
      getCategoryData();
    }
  }, []);

  const showErrorWithOk = (message) => {
    setErrorModalOptions({
      open: true,
      title: i18n.t('error'),
      message,
      ok: true,
      onOk: () => {
        closeErrorModal();
      },
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const onStripeButtonClick = async () => {
    if (!hasStripeAccount) {
      setButtonLoading(true);
      const res = await createStripeAccount(token);
      if (res && res.success) {
        const aLink = res.accountLink;
        window.location.replace(aLink);
        setButtonLoading(false);
      } else {
        showErrorWithOk(i18n.t('stripeAccountCreateError'));
      }
    } else {
      window.location.replace(accountLink);
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const hasStripeAccountRes = await getIfShopHasStripeAccount(token);
      if (hasStripeAccountRes && hasStripeAccountRes.success) {
        setHasStripeAccount(hasStripeAccountRes.hasStripeAccount);
        if (hasStripeAccountRes.hasStripeAccount) {
          const stripeAccRes = await getStripeAccount(token);
          if (stripeAccRes && stripeAccRes.success) {
            if (
              stripeAccRes.account.requirements.currently_due !== null &&
              stripeAccRes.account.requirements.currently_due.length > 0
            ) {
              const stripeAccLinkRes = await getStripeAccountLink(token);
              if (stripeAccLinkRes && stripeAccLinkRes.success) {
                setAccountLink(stripeAccLinkRes.accountLink);
                setIsWarning(true);
              } else {
                showErrorWithOk(i18n.t('stripeAccountLinkError'));
              }
            }
          } else {
            showErrorWithOk(i18n.t('stripeAccountError'));
          }
        }
      } else {
        showErrorWithOk(i18n.t('stripeAccountError'));
      }
      const meRes = await getMe(token);
      if (meRes && meRes.success) {
        dispatch({
          type: 'update',
          shop: meRes.me.shop,
        });
        if (meRes.me.shop.allowedPaymentMethods.cash) {
          setIsCashChecked(true);
        }
        if (meRes.me.shop.allowedPaymentMethods.card) {
          setIsCreditCardChecked(true);
        }
      } else {
        showErrorWithOk(i18n.t('shopDataError'));
      }
      setLoading(false);
    } catch (error) {
      showErrorWithOk(i18n.t('dataLoadError'));
      setRefreshRequired(true);
    }
  };

  const onCashChange = async () => {
    try {
      const res = await updateAllowedPaymentMethods(token, isCreditCardChecked, !isCashChecked);
      if (res && res.success) {
        setIsCashChecked(!isCashChecked);
      } else {
        showErrorWithOk(i18n.t('cashOptionChangeError'));
      }
    } catch (error) {
      showErrorWithOk(i18n.t('cashOptionChangeError'));
    }
  };

  const onCreditCardChange = async () => {
    if (hasStripeAccount && !isWarning) {
      try {
        const res = await updateAllowedPaymentMethods(token, !isCreditCardChecked, isCashChecked);
        if (res && res.success) {
          setIsCreditCardChecked(!isCreditCardChecked);
        } else {
          showErrorWithOk(i18n.t('cardOptionChangeError'));
        }
      } catch (error) {
        showErrorWithOk(i18n.t('cardOptionChangeError'));
      }
    } else {
      showErrorWithOk(i18n.t('stripeAccountDataError'));
    }
  };

  const onDarkModeChange = async () => {
    const res = await updateShop(token, { darkMode: !isDarkModeChecked });
    if (res && res.success) {
      setIsDarkModeChecked(!isDarkModeChecked);
      setSoftRefreshNeed(true);
    } else {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const getCategoryData = async () => {
    try {
      const res = await getAllCategoriesOfShop(shop._id.toString());
      if (res && res.success) {
        setCategories(res.categories);
      }
    } catch (error) {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const getShopData = async () => {
    try {
      const res = await getMe(token);
      if (res && res.success) {
        dispatch({
          type: 'update',
          shop: res.me.shop,
        });
        setSoftRefreshNeed(false);
      }
    } catch (_) {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const getEmployees = async () => {
    if (shop) {
      const res = await getAllEmployees(token, shop._id);
      if (res && res.success) {
        setEmployeeTableData({
          employees: res.users,
          selectedColumn: null,
          sortDirection: 'descending',
          isSearching: false,
          searchTerm: '',
        });
      }
    }
  };

  const closeErrorModal = () => {
    setErrorModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const onAddressModalClose = (refreshNeeded) => {
    setAddressModalOptions({ open: false });
    setSoftRefreshNeed(refreshNeeded);
  };

  const onOpeningHoursClose = (refreshNeeded) => {
    setOpeningHoursModalOptions({ open: false });
    setSoftRefreshNeed(refreshNeeded);
  };

  const onEmployeeModalClose = async (refreshNeeded) => {
    setEmployeeModalOptions({ open: false, employee: null });
    await getEmployees();
    setSoftRefreshNeed(refreshNeeded);
  };

  const onPrinterModalClose = (refreshNeeded) => {
    setPrinterModalOptions({ open: false, printer: null });
    setSoftRefreshNeed(refreshNeeded);
  };

  const onTerminalReaderModalClose = (refreshNeeded) => {
    setTerminalReaderModalOptions({ open: false, terminal: null });
    setSoftRefreshNeed(refreshNeeded);
  };

  const saveColor = async (color) => {
    const data = {};
    if (color !== shop.color) {
      data.color = color;
    }
    try {
      const res = await updateShop(token, data);
      if (res && res.success) {
        console.log();
      } else {
        showErrorWithOk(i18n.t('generalUpdateError'));
      }
    } catch (_) {
      showErrorWithOk(i18n.t('generalUpdateError'));
    }
  };

  const onPrintPerCategoryChanged = async () => {
    const res = await updateShop(token, { printPerCategory: !allowPrintPerCategory });
    if (res && res.success) {
      setAllowPrintPerCategory(!allowPrintPerCategory);
      setSoftRefreshNeed(true);
    } else {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const onAutoPrintChanged = async () => {
    const res = await updateShop(token, { autoPrint: !allowAutoPrint });
    if (res && res.success) {
      setAllowAutoPrint(!allowAutoPrint);
      setSoftRefreshNeed(true);
    } else {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const onAutoPrintCustomerChanged = async () => {
    const res = await updateShop(token, { autoPrintCustomer: !allowAutoPrintCustomer });
    if (res && res.success) {
      setAllowAutoPrintCustomer(!allowAutoPrintCustomer);
      setSoftRefreshNeed(true);
    } else {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const onAvailabilityChanged = async () => {
    const res = await updateShop(token, { isOpen: !isShopOpen });
    if (res && res.success) {
      setIsShopOpen(!isShopOpen);
      setSoftRefreshNeed(true);
    } else {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const onCashBookChanged = async () => {
    const res = await updateShop(token, { allowCashBook: !allowCashBook });
    if (res && res.success) {
      setAllowCashBook(!allowCashBook);
      setSoftRefreshNeed(true);
    } else {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const onPinRequiredChanged = async () => {
    const res = await updateShop(token, { isPinRequired: !allowPinRequired });
    if (res && res.success) {
      setAllowPinRequired(!allowPinRequired);
      setSoftRefreshNeed(true);
    } else {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const onAllowBarcodeScannerChanged = async () => {
    const res = await updateShop(token, { hasBarcodeScanner: !allowBarcodeScanner });
    if (res && res.success) {
      setAllowBarcodeScanner(!allowBarcodeScanner);
      setSoftRefreshNeed(true);
    } else {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const onAllowDiverseProductsChanged = async () => {
    const res = await updateShop(token, { allowDiverseProducts: !allowDiverseProducts });
    if (res && res.success) {
      setAllowDiverseProducts(!allowDiverseProducts);
      setSoftRefreshNeed(true);
    } else {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const onAllowDiscountsChanged = async () => {
    const res = await updateShop(token, { allowDiscounts: !allowDiscounts });
    if (res && res.success) {
      setAllowDiscounts(!allowDiscounts);
      setSoftRefreshNeed(true);
    } else {
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const onAllowTipsChanged = async (option) => {
    const currentOption = selectedTipOption;
    setSelectedTipOption(option);
    const res = await updateShop(token, {
      tipsAllowed: option !== tipOptions.off,
      tipOption: option,
    });
    if (res && res.success) {
      setSoftRefreshNeed(true);
    } else {
      setSelectedTipOption(currentOption);
      showErrorWithOk(i18n.t('settingsShopLoadError'));
      setRefreshRequired(true);
    }
  };

  const onTakeAwayTableChanged = async () => {
    if (allowTakeAwayTable === true && allowInHouseTable === false) {
      showErrorWithOk(i18n.t('takeAwayError'));
    } else {
      const res = await updateShop(token, { isTakeAwayTableAllowed: !allowTakeAwayTable });
      if (res && res.success) {
        setAllowTakeAwayTable(!allowTakeAwayTable);
        setSoftRefreshNeed(true);
      } else {
        showErrorWithOk(i18n.t('settingsShopLoadError'));
        setRefreshRequired(true);
      }
    }
  };

  const onInHouseTableChanged = async () => {
    if (allowInHouseTable === true && allowTakeAwayTable === false) {
      showErrorWithOk(i18n.t('inHouseError'));
    } else {
      const res = await updateShop(token, { isInHouseTableAllowed: !allowInHouseTable });
      if (res && res.success) {
        setAllowInHouseTable(!allowInHouseTable);
        setSoftRefreshNeed(true);
      } else {
        showErrorWithOk(i18n.t('settingsShopLoadError'));
        setRefreshRequired(true);
      }
    }
  };

  const parseEmployees = (employees) =>
    employees.map((employee) => (
      <Fragment key={`${employee._id.toString()}frag`}>
        <Table.Row
          key={employee._id}
          onClick={() => setEmployeeModalOptions({ employee, open: true })}
        >
          <Table.Cell key={`${employee._id}3`} collapsing>
            {employee.role === 'owner'
              ? i18n.t('ownerTitle')
              : employee.role === 'manager'
              ? i18n.t('newManagerDropDown')
              : employee.role === 'employee'
              ? i18n.t('newEmployeeDropDown')
              : i18n.t('newTaxDropDown')}
          </Table.Cell>
          <Table.Cell key={`${employee._id}4`} collapsing>
            {employee.name ? employee.name : ''}
          </Table.Cell>
          <Table.Cell key={`${employee._id}2`} collapsing>
            {employee.email}
          </Table.Cell>
          <Table.Cell key={`${employee._id}5`} collapsing>
            {employee.pin ? employee.pin : ''}
          </Table.Cell>
          <Table.Cell key={`${employee._id}6`} collapsing>
            {(employee.tables && employee.tables.length === 1 && employee.tables[0] === 'all') ||
            employee.role === 'owner' ? (
              <p>{i18n.t('all')}</p>
            ) : (
              <p>
                {`${employee.tables.length} ${
                  employee.tables.length === 1 ? i18n.t('bookingsTable') : i18n.t('tablesHeading')
                }`}
              </p>
            )}
          </Table.Cell>
        </Table.Row>
      </Fragment>
    ));

  const parsePrinters = (printers) =>
    printers.map((printer, index) => (
      <Fragment key={`${printer._id.toString()}frag`}>
        <Table.Row
          key={printer._id}
          onClick={() => setPrinterModalOptions({ printer, open: true })}
          positive={printer.isMain === true}
        >
          <Table.Cell key={`${printer._id}2`}>{printer.description}</Table.Cell>
          <Table.Cell key={`${printer._id}3`} collapsing>
            {printer.ipAddress}
          </Table.Cell>
          <Table.Cell key={`${printer._id}5`}>
            {printer.categories.length === 1 && printer.categories[0] === 'all' ? (
              <p>{i18n.t('newPrinterAllCategories')}</p>
            ) : (
              <p>
                {`${printer.categories.length} ${
                  printer.categories.length === 1
                    ? i18n.t('itemsCategory')
                    : i18n.t('itemsCategories')
                }`}
              </p>
            )}
          </Table.Cell>
          <Table.Cell key={`${printer._id}6`} collapsing>
            {printer.rooms.length === 1 && printer.rooms[0] === 'all' ? (
              <p>{i18n.t('allRooms')}</p>
            ) : (
              <p>
                {`${printer.rooms.length} ${
                  printer.rooms.length === 1 ? i18n.t('room') : i18n.t('rooms')
                }`}
              </p>
            )}
          </Table.Cell>
        </Table.Row>
      </Fragment>
    ));

  const parseTerminalReaders = () =>
    shop &&
    shop.terminalReaders.map((terminal, index) => (
      <Fragment key={`${terminal.description + index}frag`}>
        <Table.Row onClick={() => setTerminalReaderModalOptions({ terminal, open: true })}>
          <Table.Cell>{terminal.description}</Table.Cell>
        </Table.Row>
      </Fragment>
    ));

  const calculateBETimeToTimeInputFormat = (time) => {
    if (time.from === null || time.to === null) {
      return { from: null, to: null };
    }
    const from = moment().startOf('day').add(time.from, 'minutes').format('HH:mm');
    const to = moment().startOf('day').add(time.to, 'minutes').format('HH:mm');
    return { from, to };
  };

  const parseOpeningHoursContainer = () => {
    const currentLang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    const weekdaysDE = [0, 1, 2, 3, 4, 5, 6].map((day) =>
      moment().locale('de').weekday(day).format('dddd')
    );
    const weekdaysEN = [1, 2, 3, 4, 5, 6, 0].map((day) =>
      moment().locale('en').weekday(day).format('dddd')
    );
    const weekdays = currentLang === 'de' ? weekdaysDE : weekdaysEN;
    return weekdaysEN.map((day, index) => (
      <Fragment key={`${day + index}frag`}>
        <p
          style={{
            fontSize: '12px',
            marginBottom: '4px',
            fontWeight: '500',
            color: '#666',
          }}
        >
          {weekdays[index]}
        </p>
        <p
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color:
              shop && shop.openingHours && shop.openingHours[day.toLowerCase()].length !== 0
                ? '#333'
                : '#333',
          }}
        >
          {shop && shop.openingHours && shop.openingHours[day.toLowerCase()].length !== 0
            ? parseOpeningHours(day.toLowerCase())
            : '-'}
        </p>
      </Fragment>
    ));
  };

  const parseOpeningHours = (day) =>
    shop.openingHours[day].map((openingHour) => {
      const time = calculateBETimeToTimeInputFormat(openingHour);
      return (
        <Fragment key={`${time.from}frag`}>
          <p style={{ fontFamily: 'inherit', marginBottom: '6px' }}>
            {`${time.from} - ${time.to}${i18n.t('uhrGerman')}`}
          </p>
        </Fragment>
      );
    });

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">{i18n.t('settingsHeading')}</p>
        <p className="cockpit-subtitle">{i18n.t('settingsSubtitle')}</p>
      </div>
      <div className="settings-container">
        <Grid doubling stackable columns={2}>
          <Grid.Column>
            <div className="settings-tile-container">
              <div className="settings-content-container">
                <div className="settings-header">
                  <p className="settings-title">{i18n.t('ownerTitle')}</p>
                  <Button
                    content={i18n.t('ownerRequestChange')}
                    className="settings-btn"
                    id="settings-btn"
                    onClick={() => {
                      window.location.href = `mailto:info@moaby.app?subject=Change%20of%20Shop%20Owner&body=Hello%20moaby%20Team%2C%0D%0A%0D%0AI%20would%20like%20to%20change%20the%20Owner%20of%20the%20Shop.%0D%0A%0D%0APlease%20get%20in%20contact%20with%20me%0D%0A%0D%0AShop%3A%20${
                        shop ? shop.name : ''
                      }%0D%0AShop%20ID%3A%20${
                        shop ? shop._id : ''
                      }%0D%0A%0D%0AThank%20you%2C%0D%0A${
                        shop ? `${shop.shopOwner.firstName} ${shop.shopOwner.lastName}` : ''
                      }`;
                    }}
                  />
                </div>
                <p style={{ fontSize: '13px', fontWeight: '500', color: '#666' }}>
                  {i18n.t('ownerSubtitle')}
                </p>
                <br />
                <p
                  style={{
                    fontSize: '12px',
                    marginBottom: '4px',
                    fontWeight: '500',
                    color: '#666',
                  }}
                >
                  {i18n.t('ownerName')}
                </p>
                <p className="settings-texts" style={{ fontSize: '16px', fontWeight: '600' }}>
                  {`${shop ? shop.shopOwner.firstName : ''} ${shop ? shop.shopOwner.lastName : ''}`}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    marginBottom: '4px',
                    fontWeight: '500',
                    color: '#666',
                  }}
                >
                  {i18n.t('ownerMail')}
                </p>
                <p className="settings-texts" style={{ fontSize: '16px', fontWeight: '600' }}>
                  {`${shop ? shop.shopOwner.email : ''}`}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    marginBottom: '4px',
                    fontWeight: '500',
                    color: '#666',
                  }}
                >
                  {i18n.t('ownerPhone')}
                </p>
                <p className="settings-texts" style={{ fontSize: '16px', fontWeight: '600' }}>
                  {`${shop ? shop.shopOwner.phoneNumber : ''}`}
                </p>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="settings-tile-container">
              <div className="settings-content-container">
                <div className="settings-header">
                  <p className="settings-title">{i18n.t('shopAddressTitle')}</p>
                  <Button
                    content={i18n.t('openingHoursEdit')}
                    className="settings-btn"
                    id="settings-btn"
                    onClick={() => setAddressModalOptions({ open: true })}
                  />
                </div>
                <p style={{ fontSize: '13px', fontWeight: '500', color: '#666' }}>
                  {i18n.t('shopAddressSubtitle')}
                </p>
                <br />
                <p
                  style={{
                    fontSize: '12px',
                    marginBottom: '4px',
                    fontWeight: '500',
                    color: '#666',
                  }}
                >
                  {i18n.t('shopStreet')}
                </p>
                <p className="settings-texts" style={{ fontSize: '16px', fontWeight: '600' }}>
                  {`${shop ? shop.address.street : ''}`}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    marginBottom: '4px',
                    fontWeight: '500',
                    color: '#666',
                  }}
                >
                  {i18n.t('shopZip')}
                </p>
                <p className="settings-texts" style={{ fontSize: '16px', fontWeight: '600' }}>
                  {`${shop ? shop.address.zip : ''}`}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    marginBottom: '4px',
                    fontWeight: '500',
                    color: '#666',
                  }}
                >
                  {i18n.t('shopCity')}
                </p>
                <p className="settings-texts" style={{ fontSize: '16px', fontWeight: '600' }}>
                  {`${shop ? shop.address.city : ''}`}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    marginBottom: '4px',
                    fontWeight: '500',
                    color: '#666',
                  }}
                >
                  {i18n.t('ownerPhone')}
                </p>
                <p className="settings-texts" style={{ fontSize: '16px', fontWeight: '600' }}>
                  {`${shop && shop.phoneNumber ? shop.phoneNumber : '-'}`}
                </p>
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <Grid doubling stackable columns={2}>
          <Grid.Column>
            <div className="settings-tile-container-big">
              <div className="settings-content-container">
                <div className="settings-header">
                  <p className="settings-title">{i18n.t('generalSettingsTitle')}</p>
                </div>
                <p style={{ fontSize: '13px', fontWeight: '500', color: '#666', marginBottom: 20 }}>
                  {i18n.t('generalSettingsSubtitle')}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    marginBottom: '4px',
                    fontWeight: '500',
                    color: '#666',
                  }}
                >
                  {i18n.t('generalShopName')}
                </p>
                <p
                  style={{
                    fontSize: '16px',
                    fontWeight: '600',
                    color: '#333',
                  }}
                >
                  {shop ? shop.name : ''}
                </p>
                <div className="settings-key-value">
                  <p className="settings-key">{i18n.t('dailySummaryCashBook2')}</p>
                  <Toggle checked={allowCashBook} onChange={onCashBookChanged} />
                </div>
                <div className="settings-key-value">
                  <p className="settings-key">{i18n.t('pinLock')}</p>
                  <Toggle checked={allowPinRequired} onChange={onPinRequiredChanged} />
                </div>
                <div className="settings-key-value">
                  <p className="settings-key">{i18n.t('tips2')}</p>
                  <TipSelectionButtons
                    selectedTipOption={selectedTipOption}
                    onAllowTipsChanged={onAllowTipsChanged}
                    options={tipOptions}
                  />
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="settings-tile-container-large">
              <div
                className="settings-content-container"
                style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <div className="settings-header">
                  <p className="settings-title">{i18n.t('openingHoursTitle')}</p>
                  <Button
                    content={i18n.t('openingHoursEdit')}
                    className="settings-btn"
                    id="settings-btn"
                    onClick={() => setOpeningHoursModalOptions({ open: true })}
                  />
                </div>
                <p style={{ fontSize: '13px', fontWeight: '500', color: '#666', marginBottom: 20 }}>
                  {i18n.t('openingHoursSubtitle')}
                </p>
                <div
                  style={{ overflowY: 'auto', flex: '1', overscrollBehavior: 'auto' }}
                  className="opening-hours-scrollbar"
                >
                  {parseOpeningHoursContainer()}
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <Grid doubling stackable columns={2}>
          <Grid.Column>
            <div className="settings-tile-container-max2">
              <div className="settings-content-container">
                <div className="settings-header">
                  <p className="settings-title">{i18n.t('ordering')}</p>
                </div>
                <p style={{ fontSize: '13px', fontWeight: '500', color: '#666', marginBottom: 20 }}>
                  {i18n.t('orderingSubtitle')}
                </p>
                <div className="settings-key-value">
                  <p className="settings-key">In-House</p>
                  <Toggle checked={allowInHouseTable} onChange={onInHouseTableChanged} />
                </div>
                <div className="settings-key-value">
                  <p className="settings-key">Take-Away</p>
                  <Toggle checked={allowTakeAwayTable} onChange={onTakeAwayTableChanged} />
                </div>
                <div className="settings-key-value">
                  <p className="settings-key">{i18n.t('diverseProducts')}</p>
                  <Toggle checked={allowDiverseProducts} onChange={onAllowDiverseProductsChanged} />
                </div>
                <div className="settings-key-value">
                  <p className="settings-key">{i18n.t('discounting')}</p>
                  <Toggle checked={allowDiscounts} onChange={onAllowDiscountsChanged} />
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="settings-tile-container-max2">
              <div className="settings-content-container">
                <div className="settings-header">
                  <p className="settings-title">{i18n.t('printerAndScanner')}</p>
                </div>
                <p style={{ fontSize: '13px', fontWeight: '500', color: '#666', marginBottom: 20 }}>
                  {i18n.t('printerAndScannerSubtitle')}
                </p>
                <div className="settings-key-value">
                  <p className="settings-key">{i18n.t('printerPrintPerCategory')}</p>
                  <Toggle checked={allowPrintPerCategory} onChange={onPrintPerCategoryChanged} />
                </div>
                <div className="settings-key-value">
                  <p className="settings-key">
                    {i18n.t('printerAutoPrint')}
                    <Popup
                      on={['hover', 'click']}
                      // eslint-disable-next-line prettier/prettier
                      trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                      content={i18n.t('infoIBon')}
                      popper={<div style={{ filter: 'none' }} />}
                    />
                  </p>
                  <Toggle checked={allowAutoPrint} onChange={onAutoPrintChanged} />
                </div>
                <div className="settings-key-value">
                  <p className="settings-key">
                    {i18n.t('kBonAutoPrint')}
                    <Popup
                      on={['hover', 'click']}
                      // eslint-disable-next-line prettier/prettier
                      trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                      content={i18n.t('infoKBon')}
                      popper={<div style={{ filter: 'none' }} />}
                    />
                  </p>
                  <Toggle checked={allowAutoPrintCustomer} onChange={onAutoPrintCustomerChanged} />
                </div>
                <div className="settings-key-value">
                  <p className="settings-key">Barcode Scanner</p>
                  <Toggle checked={allowBarcodeScanner} onChange={onAllowBarcodeScannerChanged} />
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <Grid doubling stackable columns={1}>
          <Grid.Column>
            <div className="settings-tile-container-medium">
              <div className="settings-content-container">
                <div className="settings-header">
                  <p className="settings-title">{i18n.t('employeesTitle')}</p>
                  <Button
                    content={i18n.t('employeesAddEmployee')}
                    className="settings-btn"
                    id="settings-btn"
                    onClick={() => setEmployeeModalOptions({ open: true, employee: null })}
                  />
                </div>
                <p style={{ fontSize: '13px', fontWeight: '500', color: '#666' }}>
                  {i18n.t('employeesSubtitle')}
                </p>
                <div
                  className="settings-table-container"
                  style={{ borderRadius: 14, overscrollBehavior: 'auto' }}
                >
                  <Table fixed celled selectable padded sortable className="settings-table">
                    {matches === true ? (
                      <>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                              {i18n.t('newEmployeeRole')}
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                              Name
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                              {i18n.t('newEmployeeMail')}
                            </Table.HeaderCell>
                            <Table.HeaderCell width="2" style={{ backgroundColor: 'white' }}>
                              Pin
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                              {i18n.t('tablesHeading')}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                      </>
                    ) : (
                      <></>
                    )}

                    <Table.Body>
                      {employeeTableData ? parseEmployees(employeeTableData.employees) : []}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <Grid doubling stackable columns={1}>
          <Grid.Column>
            <div className="settings-tile-container-printer">
              <div className="settings-content-container">
                <div className="settings-header">
                  <p className="settings-title">{i18n.t('printersTitle')}</p>
                  <Button
                    content={i18n.t('printersAddPrinter')}
                    className="settings-btn"
                    id="settings-btn"
                    onClick={() => setPrinterModalOptions({ open: true, printer: null })}
                  />
                </div>
                <p style={{ fontSize: '13px', fontWeight: '500', color: '#666' }}>
                  {i18n.t('printersSubtitle1')}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    marginTop: '-10px',
                    color: '#2c662d',
                  }}
                >
                  {i18n.t('printerSubtitle2')}
                  <Popup
                    on={['hover', 'click']}
                    // eslint-disable-next-line prettier/prettier
                    trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                    content={i18n.t('printerDescription')}
                    popper={<div style={{ filter: 'none' }} />}
                  />
                </p>
                <div
                  className="settings-table-container"
                  style={{ borderRadius: 14, overscrollBehavior: 'auto' }}
                >
                  <Table fixed celled selectable padded sortable className="settings-table">
                    {matches === true ? (
                      <>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                              {i18n.t('newPRinterDescription')}
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                              {i18n.t('shopAddressTitle')}
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                              {i18n.t('newPrinterCategories')}
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                              {i18n.t('rooms')}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                      </>
                    ) : (
                      <></>
                    )}

                    <Table.Body>
                      {shop ? (shop.printers ? parsePrinters(shop.printers) : []) : []}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <Grid doubling stackable columns={2}>
          <Grid.Column>
            <div className="settings-tile-container-max">
              <div className="settings-content-container">
                <div className="settings-header">
                  <p className="settings-title">QR-App</p>
                </div>
                <p style={{ fontSize: '13px', fontWeight: '500', color: '#666', marginBottom: 20 }}>
                  {i18n.t('qrAppDescription')}
                </p>
                <div className="settings-key-value">
                  <p className="settings-key">{i18n.t('generalOpen')}</p>
                  <Toggle checked={isShopOpen} onChange={onAvailabilityChanged} />
                </div>
                {loading ? (
                  <Loader size="medium" active={loading} />
                ) : (
                  <>
                    <div className="settings-key-value">
                      <p className="settings-key">{i18n.t('paymentCreditCard')}</p>
                      <Toggle checked={isCreditCardChecked} onChange={onCreditCardChange} />
                    </div>
                    <div className="settings-key-value">
                      <p className="settings-key">{i18n.t('paymentCashPayment')}</p>
                      <Toggle checked={isCashChecked} onChange={onCashChange} />
                    </div>
                  </>
                )}
                <div className="settings-key-value">
                  <p className="settings-key">
                    Dark Mode
                    <Popup
                      on={['hover', 'click']}
                      // eslint-disable-next-line prettier/prettier
                      trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                      content={i18n.t('darkModeInfo')}
                      popper={<div style={{ filter: 'none' }} />}
                    />
                  </p>
                  <Toggle checked={isDarkModeChecked} onChange={onDarkModeChange} />
                </div>
                <div className="settings-key-value">
                  <p className="settings-key">{i18n.t('mainColor')}</p>
                  <div style={{ marginRight: -2 }}>
                    <div
                      style={{
                        borderRadius: 6,
                        width: 51,
                        height: 28,
                        padding: 2,
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 4,
                          width: '100%',
                          height: '100%',
                          backgroundColor: color,
                        }}
                        onClick={() => setShowColorPicker(!showColorPicker)}
                      />
                      {showColorPicker ? (
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: '2',
                            marginTop: -30,
                            paddingBottom: 20,
                            right: 78,
                          }}
                        >
                          <div
                            style={{
                              position: 'fixed',
                              top: '20px',
                              right: '0px',
                              bottom: '0px',
                              left: '0px',
                            }}
                            onClick={() => setShowColorPicker(false)}
                          />
                          <ChromePicker
                            className="picker"
                            disableAlpha
                            color={color}
                            onChangeComplete={(color) => {
                              setColor(color.hex);
                              saveColor(color.hex);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="settings-tile-container-max">
              <div className="settings-content-container">
                <div className="settings-header">
                  <p className="settings-title">Stripe</p>
                  <Button
                    content={i18n.t('newCardReader')}
                    className="settings-btn"
                    id="settings-btn"
                    onClick={() => {
                      window.location.href = `mailto:info@moaby.app?subject=Neues%20Kartenleseger%C3%A4t&body=Hallo%2C%0D%0A%0D%0AIch%20m%C3%B6chte%20ein%20neues%20Kartenleseger%C3%A4t%2C%20bitte%20kontaktiert%20mich.%0D%0A%0D%0AShop%3A%20
                      ${shop ? shop.name : ''}%0D%0AShop%20ID%3A%20${
                        shop ? shop._id : ''
                      }%0D%0A%0D%0AViele%20Gr%C3%BC%C3%9Fe%0D%0A${
                        shop ? `${shop.shopOwner.firstName} ${shop.shopOwner.lastName}` : ''
                      }`;
                    }}
                  />
                </div>
                <p style={{ fontSize: '13px', fontWeight: '500', color: '#666' }}>
                  {i18n.t('stripeAccTitle')}
                </p>
                {loading ? (
                  <Loader size="medium" active={loading} />
                ) : (
                  <>
                    <p
                      style={{
                        fontSize: '16px',
                        marginBottom: '14px',
                        fontWeight: '600',
                      }}
                    >
                      {i18n.t('stripeAcc')}
                    </p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '4px',
                        fontWeight: '500',
                        color: '#666',
                      }}
                    >
                      {isWarning ? (
                        <span style={{ color: 'orange', fontWeight: '600' }}>
                          {i18n.t('paymentStripeWarningSubtitle')}
                        </span>
                      ) : (
                        <>
                          {hasStripeAccount ? (
                            <span style={{ color: 'green', fontWeight: '600' }}>
                              {i18n.t('paymentStripeSuccess')}
                            </span>
                          ) : (
                            <span style={{ color: 'red', fontWeight: '600' }}>
                              {i18n.t('paymentRightTitle')}
                            </span>
                          )}
                        </>
                      )}
                    </p>
                    {(isWarning || hasStripeAccount === false) && (
                      <Button
                        loading={buttonLoading}
                        fluid
                        onClick={onStripeButtonClick}
                        disabled={isWarning ? false : !!hasStripeAccount}
                        className="payments-stripe-button"
                        color={isWarning ? 'orange' : 'teal'}
                      >
                        <Button.Content
                          content={
                            hasStripeAccount
                              ? i18n.t('paymentStripeManageDataButton')
                              : i18n.t('paymentCreateStripeAccountButton')
                          }
                          className="payments-stripe-button-text"
                        />
                      </Button>
                    )}
                    <br />
                    {!hasStripeAccount ? (
                      <p style={{ fontSize: '11px' }}>
                        {i18n.t('paymentStripeAgreement1')}
                        <a href="https://stripe.com/de/legal">
                          {i18n.t('paymentStripeAgreement2')}
                        </a>
                        {i18n.t('paymentStripeAgreement3')}
                      </p>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <p
                  style={{
                    fontSize: '16px',
                    marginBottom: '14px',
                    fontWeight: '600',
                  }}
                >
                  {i18n.t('cardReaders')}
                </p>
                {shop && shop.terminalReaders && shop.terminalReaders.length > 0 ? (
                  <div
                    className="settings-table-container"
                    style={{
                      height: 120,
                      borderRadius: 14,
                      marginTop: 0,
                      overscrollBehavior: 'auto',
                    }}
                  >
                    <Table
                      fixed
                      celled
                      selectable
                      padded
                      sortable
                      className="settings-table"
                      style={{ overscrollBehavior: 'auto' }}
                    >
                      <Table.Body
                        style={{ overflowY: 'scroll', maxHeight: 340, overscrollBehavior: 'auto' }}
                        className="no-scrollbar"
                      >
                        {shop && shop.terminalReaders ? parseTerminalReaders() : []}
                      </Table.Body>
                    </Table>
                  </div>
                ) : (
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: '600',
                      color: 'red',
                    }}
                  >
                    {i18n.t('noTerminalConnected')}
                  </p>
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <ErrorWarningModal
          open={errorModalOptions.open}
          title={errorModalOptions.title}
          message={errorModalOptions.message}
          ok={errorModalOptions.ok}
          onOk={errorModalOptions.onOk}
          yes={errorModalOptions.yes}
          onYes={errorModalOptions.onYes}
          no={errorModalOptions.no}
          onNo={errorModalOptions.onNo}
        />
        <AddressModal open={addressModalOptions.open} onClose={onAddressModalClose} />
        <OpeningHoursModal open={openingHoursModalOptions.open} onClose={onOpeningHoursClose} />
        <PrintersModal
          open={printerModalOptions.open}
          onClose={onPrinterModalClose}
          printer={printerModalOptions.printer}
          categories={categories}
        />
        <TerminalReaderModal
          open={terminalReaderModalOptions.open}
          onClose={onTerminalReaderModalClose}
          terminal={terminalReaderModalOptions.terminal}
        />
        <EmployeesModal
          open={employeeModalOptions.open}
          onClose={onEmployeeModalClose}
          employee={employeeModalOptions.employee}
        />
      </div>
    </>
  );
};

export default SettingsScreen;
