/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';

const AnimatedTabs = ({
  tabs,
  selectedTab,
  setSelectedTab,
  onChangeTab,
  containerWidth = tabs.length * 120,
  config = {
    backgroundColor: '#eeecfc',
    activeTextColor: '#7268cd',
    inactiveTextColor: '#7268cdc2',
    tabWidth: 140,
  },
}) => {
  const [sliderStyle, setSliderStyle] = useState({});
  const tabRefs = useRef(new Map());

  useEffect(() => {
    const currentTabRef = tabRefs.current.get(selectedTab);
    if (currentTabRef) {
      setSliderStyle({
        transform: `translateX(${currentTabRef.offsetLeft}px)`,
        width: `${currentTabRef.offsetWidth}px`,
      });
    }
  }, [selectedTab]);

  const containerStyle = {
    display: 'flex',
    gap: 8,
    marginTop: 8,
    marginLeft: 24,
    overflow: 'hidden',
    width: containerWidth,
    position: 'absolute',
    height: 32,
    textAlign: 'center',
    alignItems: 'center',
    animation: 'titleFade 0.3s ease-out',
  };

  const sliderBaseStyle = {
    position: 'absolute',
    height: '100%',
    backgroundColor: config.backgroundColor,
    borderRadius: 12,
    transition: 'all 0.3s ease',
    zIndex: 0,
  };

  const getTabStyle = (tabId) => ({
    position: 'relative',
    zIndex: 1,
    borderRadius: 12,
    padding: 4,
    paddingLeft: 6,
    paddingRight: 6,
    color: selectedTab === tabId ? config.activeTextColor : config.inactiveTextColor,
    fontWeight: selectedTab === tabId ? 700 : 400,
    cursor: 'pointer',
    margin: 0,
    width: config.tabWidth,
    transition: 'color 0.3s ease, font-weight 0.3s ease',
  });

  return (
    <div style={containerStyle}>
      <div style={{ ...sliderBaseStyle, ...sliderStyle }} />

      {tabs.map((tab) => (
        <p
          key={tab.id}
          ref={(el) => {
            if (el) {
              tabRefs.current.set(tab.id, el);
            }
          }}
          style={getTabStyle(tab.id)}
          onClick={() => {
            setSelectedTab(tab.id);
            if (onChangeTab) onChangeTab(tab.id);
          }}
        >
          {tab.label}
        </p>
      ))}
    </div>
  );
};

export default AnimatedTabs;
