/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-useless-escape */
/* eslint-disable no-lonely-if */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, Input, Dropdown, Icon } from 'semantic-ui-react';
import {
  createEmployeeUser,
  updateEmail,
  updatePassword,
  createManagerUser,
  deleteEmployee,
  updateIsPrinting,
  updatePin,
  updateName,
  updateIsPaying,
  updateIsOrdering,
  updateIsDiscounting,
  createTaxUser,
} from '../../../api/UserAPI';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import Toggle from '../../../components/general/Toggle/Toggle';
import { useStateValue } from '../../../core/context/StateProvider';
import './SettingsScreen.css';
import EmployeeTablesModal from './SettingsEmployeeTableModal';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const EmployeesModal = (props) => {
  const { onClose, open, employee } = props;
  const [{ token, shop, user }, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedDropdownOptions, setDropdownOptions] = useState('');
  const [isPwButtonDisabled, setIsPwButtonDisabled] = useState(false);
  const [isEmailButtonDisabled, setIsEmailButtonDisabled] = useState(false);
  const [isPinButtonDisabled, setIsPinButtonDisabled] = useState(false);
  const [isNameButtonDisabled, setIsNameButtonDisabled] = useState(false);
  const [showPwChange, setShowPwChange] = useState(false);
  const [showPinChange, setShowPinChange] = useState(false);
  const [showEmailChange, setShowEmailChange] = useState(false);
  const [showNameChange, setShowNameChange] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [isEmployeeRightsSet, setIsEmployeeRightsSet] = useState(false);
  const [employeeRights, setEmployeeRights] = useState({
    canDiscount: false,
    canPrint: false,
    canOrder: false,
    canPay: false,
  });
  const [oldPassword, setOldPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newName, setNewName] = useState('');
  const [newPin, setNewPin] = useState('');
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });
  const [assignedTablesModal, setAssignedTablesModal] = useState({ open: false });

  useEffect(() => {
    if (
      open === true &&
      employee &&
      isEmployeeRightsSet === false &&
      (employee.isPaying !== employeeRights.canPay ||
        employee.isPrinting !== employeeRights.canPrint ||
        employee.isOrdering !== employeeRights.canOrder ||
        employee.isDiscounting !== employeeRights.canDiscount)
    ) {
      setEmployeeRights({
        canDiscount: employee.isDiscounting,
        canPay: employee.isPaying,
        canPrint: employee.isPrinting,
        canOrder: employee.isOrdering,
      });
      setIsEmployeeRightsSet(true);
    }
    if (
      employee &&
      isEmployeeRightsSet === true &&
      (employee.isPaying !== employeeRights.canPay ||
        employee.isPrinting !== employeeRights.canPrint ||
        employee.isOrdering !== employeeRights.canOrder ||
        employee.isDiscounting !== employeeRights.canDiscount)
    ) {
      setIsSaveDisabled(false);
    }
    if (
      employee &&
      isEmployeeRightsSet === true &&
      employee.isPaying === employeeRights.canPay &&
      employee.isPrinting === employeeRights.canPrint &&
      employee.isOrdering === employeeRights.canOrder &&
      employee.isDiscounting === employeeRights.canDiscount &&
      showEmailChange === false &&
      showNameChange === false &&
      showPinChange === false &&
      showPwChange === false
    ) {
      setIsSaveDisabled(true);
    }
  });

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onDropDownChange = (value) => {
    setDropdownOptions(value);
    if (value.toLowerCase() === 'manager') {
      setEmployeeRights({
        ...employeeRights,
        canPay: true,
        canOrder: true,
      });
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.replaceAll(/\s/g, ''));
  };

  const validatePassword = (password) => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return re.test(password.replaceAll(/\s/g, ''));
  };

  const onSave = async () => {
    if (employee) {
      setIsLoading(true);
      if (newEmail && showEmailChange === true) {
        if (validateEmail(newEmail)) {
          const data = {};
          data.email = newEmail;
          data.oldEmail = employee.email;
          const res = await updateEmail(token, data);
          if (res && res.success) {
            closeModal(true);
            return;
          }
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('emailUpdateError'),
          });
        } else {
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('emailNotValidError'),
          });
        }
      } else if (newPassword && showPwChange === true) {
        if (newPasswordRepeat && oldPassword) {
          if (newPasswordRepeat === newPassword && newPassword.length > 5) {
            const data = {};
            data.oldPassword = oldPassword;
            data.newPassword = newPassword;
            const res = await updatePassword(
              token,
              data.oldPassword,
              data.newPassword,
              employee._id
            );
            if (res && res.success) {
              closeModal(true);
              if (user._id.toString() === employee._id.toString()) {
                window.location.href = `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_SHOPS_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/login`;
                dispatch({
                  type: 'logout',
                });
              }
              return;
            }
            setIsLoading(false);
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('passwordUpdateError'),
            });
          } else {
            setIsLoading(false);
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('passwordTooShortError'),
            });
          }
        } else {
          setIsLoading(false);
          setErrorWarningModalOptions({ open: true, message: i18n.t('fillOutAllFieldsError') });
        }
      } else if (newPin && showPinChange === true) {
        if (newPin.length === 4) {
          const data = {};
          data.pin = newPin;
          const res = await updatePin(token, data.pin, employee._id);
          if (res && res.success) {
            closeModal(true);
            return;
          }
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('pinChangeError1'),
          });
        } else {
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('pinChangeError2'),
          });
        }
      } else if (newName && showNameChange === true) {
        if (newName.length > 0) {
          const data = {};
          data.name = newName;
          const res = await updateName(token, data.name, employee._id);
          if (res && res.success) {
            closeModal(true);
            return;
          }
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('nameChangeError1'),
          });
        } else {
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('nameChangeError2'),
          });
        }
      }
      if (employee.isPrinting !== employeeRights.canPrint) {
        setIsLoading(true);
        const isPrintingAllowed = employeeRights.canPrint ? 'true' : 'false';
        const res = await updateIsPrinting(token, employee._id, isPrintingAllowed);
        if (res && res.success) {
          setIsLoading(false);
          closeModal(true);
        } else {
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('emailPrintingStatusError'),
          });
        }
      }
      if (employee.isPaying !== employeeRights.canPay) {
        setIsLoading(true);
        const isPaying = employeeRights.canPay ? 'true' : 'false';
        const res = await updateIsPaying(token, employee._id, isPaying);
        if (res && res.success) {
          setIsLoading(false);
          closeModal(true);
        } else {
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('rightsChangeError1'),
          });
        }
      }
      if (employee.isOrdering !== employeeRights.canOrder) {
        setIsLoading(true);
        const isOrdering = employeeRights.canOrder ? 'true' : 'false';
        const res = await updateIsOrdering(token, employee._id, isOrdering);
        if (res && res.success) {
          setIsLoading(false);
          closeModal(true);
        } else {
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('rightsChangeError2'),
          });
        }
      }
      if (employee.isDiscounting !== employeeRights.canDiscount) {
        setIsLoading(true);
        const isDiscounting = employeeRights.canDiscount ? 'true' : 'false';
        const res = await updateIsDiscounting(token, employee._id, isDiscounting);
        if (res && res.success) {
          setIsLoading(false);
          closeModal(true);
        } else {
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('rightsChangeError3'),
          });
        }
      }
    } else {
      if (
        selectedDropdownOptions === 'tax' &&
        newEmail &&
        newPassword &&
        newPasswordRepeat &&
        !employee
      ) {
        if (newPassword === newPasswordRepeat && validatePassword(newPassword)) {
          const data = {};
          if (validateEmail(newEmail)) {
            data.email = newEmail;
            data.password = newPassword;
            data.isPrinting = false;
            data.isOrdering = false;
            data.isPaying = false;
            data.isDiscounting = false;
            data.pin = '----';
            data.name = newName;
            setIsLoading(true);
            const res = await createTaxUser(token, data);
            if (res && res.success) {
              closeModal(true);
            } else {
              setIsLoading(false);
              setErrorWarningModalOptions({
                open: true,
                message: i18n.t('taxCreateError'),
              });
            }
          } else {
            setIsLoading(false);
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('emailNotValidError'),
            });
          }
        } else {
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('passwordNotValidError'),
          });
        }
      } else if (
        selectedDropdownOptions &&
        newEmail &&
        newPassword &&
        newPasswordRepeat &&
        !employee &&
        newPin &&
        newName
      ) {
        if (newPassword === newPasswordRepeat && validatePassword(newPassword)) {
          const data = {};
          if (newPin.length !== 4) {
            setIsLoading(false);
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('pinDigitsError'),
            });
            return;
          }
          if (validateEmail(newEmail)) {
            data.email = newEmail;
            data.password = newPassword;
            data.isPrinting = employeeRights.canPrint;
            data.isOrdering = employeeRights.canOrder;
            data.isPaying = employeeRights.canPay;
            data.isDiscounting = employeeRights.canDiscount;
            data.pin = newPin;
            data.name = newName;
            if (selectedDropdownOptions.toLowerCase() === 'employee') {
              setIsLoading(true);
              const res = await createEmployeeUser(token, data);
              if (res && res.success) {
                closeModal(true);
              } else {
                setIsLoading(false);
                setErrorWarningModalOptions({
                  open: true,
                  message: i18n.t('employeeCreateError'),
                });
              }
            }
            if (selectedDropdownOptions.toLowerCase() === 'manager') {
              data.isPaying = true;
              data.isOrdering = true;
              setIsLoading(true);
              const res = await createManagerUser(token, data);
              if (res && res.success) {
                closeModal(true);
              } else {
                setIsLoading(false);
                setErrorWarningModalOptions({
                  open: true,
                  message: i18n.t('managerCreateError'),
                });
              }
            }
          } else {
            setIsLoading(false);
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('emailNotValidError'),
            });
          }
        } else {
          setIsLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('passwordNotValidError'),
          });
        }
      } else {
        setIsLoading(false);
        setErrorWarningModalOptions({ open: true, message: i18n.t('fillOutAllFieldsError') });
      }
    }
  };

  const onDelete = async () => {
    setIsDeleteLoading(true);
    setWarningModalOptions({
      open: true,
      title: i18n.t('employeeDeletionTitle'),
      message: i18n.t('employeeDeletionMessage'),
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        const res = await deleteEmployee(token, employee._id);
        if (res && res.success) {
          closeModal(true);
        } else {
          setIsDeleteLoading(false);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('employeeDeletionError'),
          });
        }
      },
      no: true,
      onNo: () => {
        setIsDeleteLoading(false);
        setWarningModalOptions({
          open: false,
          title: '',
          message: '',
          ok: false,
          onOk: null,
          yes: false,
          onYes: null,
          no: false,
          onNo: null,
        });
      },
    });
  };

  const closeModal = (refreshNeeded) => {
    onClose(refreshNeeded);
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
    setNewPassword(null);
    setNewEmail(null);
    setNewPin(null);
    setNewName(null);
    setNewPasswordRepeat(null);
    setOldPassword(null);
    setIsSaveDisabled(true);
    setIsLoading(false);
    setIsPwButtonDisabled(false);
    setIsEmailButtonDisabled(false);
    setIsPinButtonDisabled(false);
    setIsNameButtonDisabled(false);
    setShowPwChange(false);
    setShowEmailChange(false);
    setShowPinChange(false);
    setShowNameChange(false);
    setIsDeleteLoading(false);
    setDropdownOptions('');
    setIsEmployeeRightsSet(false);
    setEmployeeRights({
      canDiscount: false,
      canPrint: false,
      canOrder: false,
      canPay: false,
    });
  };

  const dropdownOptions = [
    {
      key: 'employee',
      text: i18n.t('newEmployeeDropDown'),
      value: 'employee',
    },
    {
      key: 'manager',
      text: i18n.t('newManagerDropDown'),
      value: 'manager',
    },
    {
      key: 'tax',
      text: i18n.t('newTaxDropDown'),
      value: 'tax',
    },
  ];

  const onCloseEmployeeTablesModal = (fullClose) => {
    setAssignedTablesModal({ open: false });
    if (fullClose) {
      closeModal(true);
    }
  };

  return (
    <>
      <AnimatedModal
        open={open}
        dimmer="blurring"
        size={employee ? 'medium' : 'mini'}
        closeOnEscape
        closeOnDimmerClick
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{ borderBottom: 'none', backgroundColor: '#f5f5f9', fontFamily: 'Montserrat' }}
        >
          {employee ? employee.email : i18n.t('employeesAddEmployee')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{
            overflowX: 'hidden',
            backgroundColor: '#f5f5f9',
            paddingTop: 10,
            paddingBottom: 8,
          }}
          className="no-scrollbar"
        >
          <div style={{}}>
            {employee ? (
              <>
                {showEmailChange === false &&
                  showPwChange === false &&
                  showPinChange === false &&
                  showNameChange === false && (
                    <>
                      <p>{i18n.t('newEmployeeRole')}</p>
                      <p
                        style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          color: '#333',
                          marginTop: '-15px',
                        }}
                      >
                        {employee.role === 'owner'
                          ? i18n.t('ownerTitle')
                          : employee.role === 'manager'
                          ? i18n.t('newManagerDropDown')
                          : employee.role === 'employee'
                          ? i18n.t('newEmployeeDropDown')
                          : i18n.t('newTaxDropDown')}
                      </p>
                      {employee.name && (
                        <>
                          <p>{i18n.t('itemsTitle')}</p>
                          <p
                            style={{
                              fontSize: '16px',
                              fontWeight: '600',
                              color: '#333',
                              marginTop: '-15px',
                            }}
                          >
                            {employee.name}
                          </p>
                        </>
                      )}
                      <p>{i18n.t('newEmployeeMail')}</p>
                      <p
                        style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          color: '#333',
                          marginTop: '-15px',
                        }}
                      >
                        {employee.email}
                      </p>
                      {employee.role !== 'tax' ? (
                        <>
                          {employee.pin && (
                            <>
                              <p>Pin</p>
                              <p
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '600',
                                  color: '#333',
                                  marginTop: '-15px',
                                }}
                              >
                                {employee.pin}
                              </p>
                            </>
                          )}
                          <div className="settings-employee-table-flex-container">
                            <div
                              style={{
                                width: '100%',
                                justifyContent: 'space-between',
                                backgroundColor: 'white',
                                padding: 12,
                                borderRadius: 14,
                              }}
                            >
                              <p style={{ fontWeight: 700, fontSize: '16px', marginBottom: 10 }}>
                                {i18n.t('permissions')}
                              </p>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <p>{i18n.t('printOrders')}</p>
                                <div
                                  style={
                                    showEmailChange || showPwChange
                                      ? { right: '26px', position: 'relative' }
                                      : { right: '12px', position: 'relative' }
                                  }
                                >
                                  <Toggle
                                    style={{ position: 'relative', right: 0 }}
                                    onChange={() => {
                                      setEmployeeRights({
                                        ...employeeRights,
                                        canPrint: !employeeRights.canPrint,
                                      });
                                    }}
                                    checked={employeeRights.canPrint}
                                  />
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>{i18n.t('takeOrders')}</p>
                                <div
                                  style={
                                    showEmailChange || showPwChange
                                      ? { right: '26px', position: 'relative' }
                                      : { right: '12px', position: 'relative' }
                                  }
                                >
                                  <Toggle
                                    style={{ position: 'relative', right: 0 }}
                                    onChange={() => {
                                      if (employee.role === 'employee') {
                                        if (employeeRights.canPay === false) {
                                          setEmployeeRights({
                                            ...employeeRights,
                                            canOrder: !employeeRights.canOrder,
                                          });
                                        }
                                      }
                                    }}
                                    checked={
                                      employeeRights.canPay === false
                                        ? employeeRights.canOrder
                                        : true
                                    }
                                    readOnly={employee.role !== 'employee'}
                                  />
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>{i18n.t('payOrders')}</p>
                                <div
                                  style={
                                    showEmailChange || showPwChange
                                      ? { right: '26px', position: 'relative' }
                                      : { right: '12px', position: 'relative' }
                                  }
                                >
                                  <Toggle
                                    style={{ position: 'relative', right: 0 }}
                                    onChange={() => {
                                      if (employee.role === 'employee') {
                                        if (employeeRights.canPay === false) {
                                          setEmployeeRights({
                                            ...employeeRights,
                                            canOrder: true,
                                            canPay: true,
                                          });
                                        } else {
                                          setEmployeeRights({
                                            ...employeeRights,
                                            canPay: !employeeRights.canPay,
                                          });
                                        }
                                      }
                                    }}
                                    checked={employeeRights.canPay}
                                    readOnly={employee.role !== 'employee'}
                                  />
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>{i18n.t('discountOrders')}</p>
                                <div
                                  style={
                                    showEmailChange || showPwChange
                                      ? { right: '26px', position: 'relative' }
                                      : { right: '12px', position: 'relative' }
                                  }
                                >
                                  <Toggle
                                    style={{ position: 'relative', right: 0 }}
                                    onChange={() => {
                                      if (employeeRights.canPay === true) {
                                        setEmployeeRights({
                                          ...employeeRights,
                                          canDiscount: !employeeRights.canDiscount,
                                        });
                                      }
                                    }}
                                    checked={
                                      employeeRights.canPay ? employeeRights.canDiscount : false
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                width: '100%',
                                backgroundColor: 'white',
                                padding: 12,
                                borderRadius: 14,
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 10,
                                  height: 30,
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    margin: 0,
                                    marginBottom: 8,
                                  }}
                                >
                                  {i18n.t('tablesHeading')}
                                </p>
                                <Button
                                  size="mini"
                                  color="teal"
                                  icon="edit"
                                  circular
                                  onClick={() => setAssignedTablesModal({ open: true })}
                                  style={{ marginTop: -8 }}
                                  disabled={employee.role === 'owner'}
                                />
                              </div>
                              <p style={{ fontSize: 12, fontWeight: 500, color: '#666' }}>
                                {i18n.t('canUseTables')}
                              </p>
                              {(employee.tables &&
                                employee.tables.length === 1 &&
                                employee.tables[0] === 'all') ||
                              employee.role === 'owner' ? (
                                <p>{i18n.t('allTables')}</p>
                              ) : (
                                <p>
                                  {`${employee.tables.length} ${
                                    employee.tables.length === 1
                                      ? i18n.t('bookingsTable')
                                      : i18n.t('tablesHeading')
                                  }`}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                {showEmailChange ? (
                  <>
                    <p style={{ marginBottom: '3px', fontWeight: 600 }}>
                      {i18n.t('employeeModalNewEmail')}
                    </p>
                    <Input
                      placeholder={i18n.t('newEmployeeMail')}
                      name="email"
                      value={newEmail}
                      onChange={(e) => {
                        setNewEmail(e.target.value.replaceAll(/\s/g, ''));
                        setIsSaveDisabled(false);
                      }}
                      icon="mail"
                      iconPosition="left"
                      className="employee-input"
                    />
                  </>
                ) : (
                  <></>
                )}
                {showPinChange && (
                  <>
                    <p style={{ marginBottom: '3px', fontWeight: 600 }}>{i18n.t('newPin')}</p>
                    <Input
                      placeholder={i18n.t('newPin')}
                      name="pin"
                      type="number"
                      value={newPin}
                      onChange={(e) => {
                        if (e.target.value.length < 5) {
                          setNewPin(e.target.value.replaceAll(/\s/g, ''));
                          setIsSaveDisabled(false);
                        }
                      }}
                      icon="lock"
                      iconPosition="left"
                      className="employee-input"
                    />
                  </>
                )}
                {showNameChange && (
                  <>
                    <p style={{ marginBottom: '3px', fontWeight: 600 }}>{i18n.t('newName')}</p>
                    <Input
                      placeholder={i18n.t('newName')}
                      name="name"
                      value={newName}
                      onChange={(e) => {
                        setNewName(e.target.value.trim());
                        setIsSaveDisabled(false);
                      }}
                      icon="user"
                      iconPosition="left"
                      className="employee-input"
                    />
                  </>
                )}
                {showPwChange ? (
                  <>
                    <p style={{ marginBottom: '3px', fontWeight: 600 }}>
                      {i18n.t('employeeModalOldPassword')}
                    </p>
                    <Input
                      placeholder={i18n.t('employeeModalOldPassword')}
                      name="oPw"
                      value={oldPassword}
                      onChange={(e) => {
                        setOldPassword(e.target.value);
                        setIsSaveDisabled(false);
                      }}
                      icon="lock"
                      iconPosition="left"
                      type="password"
                      className="employee-input"
                      style={{ marginBottom: 14 }}
                    />
                    <p style={{ marginBottom: '3px', fontWeight: 600 }}>
                      {i18n.t('employeeModalNewPassword')}
                    </p>
                    <Input
                      placeholder={i18n.t('employeeModalNewPassword')}
                      name="nPw"
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        setIsSaveDisabled(false);
                      }}
                      icon="lock"
                      iconPosition="left"
                      type="password"
                      className="employee-input"
                      style={{ marginBottom: 14 }}
                    />
                    <p style={{ marginBottom: '3px', fontWeight: 600 }}>
                      {i18n.t('employeeModalNewPasswordRepeat')}
                    </p>
                    <Input
                      placeholder={i18n.t('employeeModalNewPasswordRepeat')}
                      name="nPwR"
                      value={newPasswordRepeat}
                      onChange={(e) => {
                        setNewPasswordRepeat(e.target.value);
                        setIsSaveDisabled(false);
                      }}
                      icon="lock"
                      iconPosition="left"
                      type="password"
                      className="employee-input"
                    />
                    <p style={{ fontSize: '12px', marginBottom: 14 }}>
                      {i18n.t('newEmployeePasswordSetting')}
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: 600 }}>
                      {i18n.t('employeeModalPasswordChangeMessage')}
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <p style={{ fontWeight: 700 }}>{i18n.t('newEmployeeRole')}</p>
                <Dropdown
                  selection
                  clearable
                  fluid
                  placeholder={i18n.t('newEmployeeRole')}
                  options={dropdownOptions}
                  value={selectedDropdownOptions}
                  onChange={(_, data) => {
                    onDropDownChange(data.value);
                  }}
                  className="items-dropdown"
                  id="items-dropdown"
                  style={{ marginBottom: 14 }}
                />
                <p style={{ fontWeight: 700, marginBottom: 6 }}>Name</p>
                <Input
                  placeholder="Name"
                  name="name"
                  value={newName}
                  onChange={(e) => {
                    setNewName(e.target.value.trim());
                    if (newPin && newName && newEmail && newPassword && newPasswordRepeat) {
                      setIsSaveDisabled(false);
                    }
                    if (
                      selectedDropdownOptions === 'tax' &&
                      newName &&
                      newEmail &&
                      newPassword &&
                      newPasswordRepeat
                    ) {
                      setIsSaveDisabled(false);
                    }
                  }}
                  icon="user"
                  iconPosition="left"
                  className="employee-input"
                  style={{ marginBottom: 14 }}
                />
                <p style={{ fontWeight: 700, marginBottom: 6 }}>{i18n.t('newEmployeeMail')}</p>
                <Input
                  placeholder={i18n.t('newEmployeeMail')}
                  name="email"
                  value={newEmail}
                  onChange={(e) => {
                    setNewEmail(e.target.value.replaceAll(/\s/g, ''));
                    if (newPin && newName && newEmail && newPassword && newPasswordRepeat) {
                      setIsSaveDisabled(false);
                    }
                    if (
                      selectedDropdownOptions === 'tax' &&
                      newName &&
                      newEmail &&
                      newPassword &&
                      newPasswordRepeat
                    ) {
                      setIsSaveDisabled(false);
                    }
                  }}
                  icon="mail"
                  iconPosition="left"
                  className="employee-input"
                  style={{ marginBottom: 14 }}
                />
                <p style={{ fontWeight: 700, marginBottom: 6 }}>{i18n.t('newEmployeePassword')}</p>
                <Input
                  placeholder={i18n.t('newEmployeePassword')}
                  name="password"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value.replaceAll(/\s/g, ''));
                    if (newPin && newName && newEmail && newPassword && newPasswordRepeat) {
                      setIsSaveDisabled(false);
                    }
                    if (
                      selectedDropdownOptions === 'tax' &&
                      newName &&
                      newEmail &&
                      newPassword &&
                      newPasswordRepeat
                    ) {
                      setIsSaveDisabled(false);
                    }
                  }}
                  icon="lock"
                  iconPosition="left"
                  type="password"
                  className="employee-input"
                  style={{ marginBottom: 14 }}
                />
                <p style={{ fontWeight: 700, marginBottom: 6 }}>
                  {i18n.t('newEmployeeReEnterPassowrd')}
                </p>
                <Input
                  placeholder={i18n.t('newEmployeeReEnterPassowrd')}
                  name="passwordRepeat"
                  value={newPasswordRepeat}
                  onChange={(e) => {
                    setNewPasswordRepeat(e.target.value.replaceAll(/\s/g, ''));
                    if (newPin && newName && newEmail && newPassword && newPasswordRepeat) {
                      setIsSaveDisabled(false);
                    }
                    if (
                      selectedDropdownOptions === 'tax' &&
                      newName &&
                      newEmail &&
                      newPassword &&
                      newPasswordRepeat
                    ) {
                      setIsSaveDisabled(false);
                    }
                  }}
                  icon="lock"
                  iconPosition="left"
                  type="password"
                  className="employee-input"
                  style={{ marginBottom: 4 }}
                />
                <p style={{ fontSize: '10px' }}>{i18n.t('newEmployeePasswordSetting')}</p>
                {selectedDropdownOptions !== 'tax' ? (
                  <>
                    <p style={{ fontWeight: 700, marginBottom: 6 }}>Pin</p>
                    <Input
                      placeholder="Pin"
                      name="pin"
                      type="number"
                      value={newPin}
                      onChange={(e) => {
                        if (e.target.value.length < 5) {
                          setNewPin(e.target.value.replaceAll(/\s/g, ''));
                          if (newPin && newName && newEmail && newPassword && newPasswordRepeat) {
                            setIsSaveDisabled(false);
                          }
                        }
                      }}
                      icon="lock"
                      iconPosition="left"
                      className="employee-input"
                      style={{ marginBottom: 14 }}
                    />
                    <p style={{ fontWeight: 700 }}>{i18n.t('permissions')}</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p style={{ fontWeight: '500' }}>{i18n.t('printOrders')}</p>
                      <Toggle
                        onChange={() => {
                          setEmployeeRights({
                            ...employeeRights,
                            canPrint: !employeeRights.canPrint,
                          });
                        }}
                        style={{ position: 'relative', right: 0 }}
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p style={{ fontWeight: '500' }}>{i18n.t('takeOrders')}</p>
                      <Toggle
                        style={{ position: 'relative', right: 0 }}
                        onChange={() => {
                          if (employeeRights.canPay === false) {
                            setEmployeeRights({
                              ...employeeRights,
                              canOrder: !employeeRights.canOrder,
                            });
                          }
                        }}
                        readOnly={selectedDropdownOptions.toLowerCase() === 'manager'}
                        checked={
                          selectedDropdownOptions.toLowerCase() === 'manager'
                            ? true
                            : employeeRights.canPay === false
                            ? employeeRights.canOrder
                            : true
                        }
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p style={{ fontWeight: '500' }}>{i18n.t('payOrders')}</p>
                      <Toggle
                        style={{ position: 'relative', right: 0 }}
                        readOnly={selectedDropdownOptions.toLowerCase() === 'manager'}
                        checked={
                          selectedDropdownOptions.toLowerCase() === 'manager'
                            ? true
                            : employeeRights.canPay === false
                            ? false
                            : true
                        }
                        onChange={() => {
                          if (selectedDropdownOptions.toLowerCase() === 'employee') {
                            const tmpCanPay = !employeeRights.canPay;
                            if (tmpCanPay === true) {
                              setEmployeeRights({
                                ...employeeRights,
                                canOrder: true,
                                canPay: true,
                              });
                            } else {
                              setEmployeeRights({
                                ...employeeRights,
                                canDiscount: false,
                                canPay: !employeeRights.canPay,
                              });
                            }
                          } else {
                            setEmployeeRights({
                              ...employeeRights,
                              canPay: true,
                              canOrder: true,
                            });
                          }
                        }}
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p style={{ fontWeight: '500' }}>{i18n.t('discountOrders')}</p>
                      <Toggle
                        onChange={() => {
                          if (employeeRights.canPay === true) {
                            setEmployeeRights({
                              ...employeeRights,
                              canDiscount: !employeeRights.canDiscount,
                            });
                          }
                        }}
                        style={{ position: 'relative', right: 0 }}
                        checked={employeeRights.canPay ? employeeRights.canDiscount : false}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          {employee ? (
            <>
              {employee.role !== 'owner' ? (
                <Button
                  content={i18n.t('employeeModalDelete')}
                  floated="left"
                  color="red"
                  loading={isDeleteLoading}
                  onClick={onDelete}
                />
              ) : (
                <></>
              )}
              <Button
                content={i18n.t('employeeModalChangePasswordButton')}
                floated="left"
                color="blue"
                disabled={isPwButtonDisabled}
                onClick={() => {
                  setIsEmailButtonDisabled(true);
                  setIsPwButtonDisabled(true);
                  setIsPinButtonDisabled(true);
                  setIsNameButtonDisabled(true);
                  setShowPwChange(true);
                }}
              />
              {employee.role !== 'tax' ? (
                <>
                  <Button
                    content={i18n.t('changePin')}
                    floated="left"
                    color="blue"
                    disabled={isPinButtonDisabled}
                    onClick={() => {
                      setIsEmailButtonDisabled(true);
                      setIsPwButtonDisabled(true);
                      setIsPinButtonDisabled(true);
                      setIsNameButtonDisabled(true);
                      setShowPinChange(true);
                    }}
                  />
                </>
              ) : (
                <></>
              )}
              <Button
                content={i18n.t('changeName')}
                floated="left"
                color="blue"
                disabled={isNameButtonDisabled}
                onClick={() => {
                  setIsEmailButtonDisabled(true);
                  setIsPwButtonDisabled(true);
                  setIsPinButtonDisabled(true);
                  setIsNameButtonDisabled(true);
                  setShowNameChange(true);
                }}
              />
              {employee.role !== 'owner' ? (
                <Button
                  content={i18n.t('employeeModalChangeEmail')}
                  floated="left"
                  color="blue"
                  disabled={isEmailButtonDisabled}
                  onClick={() => {
                    setIsPwButtonDisabled(true);
                    setIsEmailButtonDisabled(true);
                    setIsPinButtonDisabled(true);
                    setShowEmailChange(true);
                    setIsNameButtonDisabled(true);
                  }}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <Button
            loading={isLoading}
            content={i18n.t('shopEditSave')}
            disabled={isSaveDisabled}
            floated="right"
            onClick={onSave}
            color="teal"
          />
        </Modal.Actions>
      </AnimatedModal>
      <EmployeeTablesModal
        open={assignedTablesModal.open}
        onClose={onCloseEmployeeTablesModal}
        employee={employee}
      />
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
      />
    </>
  );
};

export default EmployeesModal;
