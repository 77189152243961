import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import JsBarcode from 'jsbarcode';
import './BarcodeView.css';
import i18n from '../../../i18n/config';

const BarcodeView = forwardRef(({ value, name, options = {} }, ref) => {
  const barcodeRef = useRef(null);
  const [showErrorView, setShowErrorView] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setShowErrorView(false);
    if (barcodeRef.current) {
      try {
        JsBarcode(barcodeRef.current, value, { ...options, valid: (valid) => setIsValid(valid) });
      } catch (error) {
        setShowErrorView(true);
      }
    }
  }, [value, options]);

  // eslint-disable-next-line no-unused-vars
  const downloadBarcode = (format = 'PNG') => {
    if (!barcodeRef.current) return;

    const svg = barcodeRef.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const URL = window.URL || window.webkitURL || window;
    const blobURL = URL.createObjectURL(svgBlob);

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0);

      const imageData = canvas.toDataURL(`image/${format.toLowerCase()}`);
      const downloadLink = document.createElement('a');
      downloadLink.download = `barcode-${name}.${format.toLowerCase()}`;
      downloadLink.href = imageData;
      downloadLink.click();

      URL.revokeObjectURL(blobURL);
    };

    img.src = blobURL;
  };

  useImperativeHandle(ref, () => ({
    downloadBarcode,
  }));

  return (
    <div style={{ width: '100%' }}>
      {showErrorView ? (
        <p style={{ textAlign: 'center', color: 'red', marginTop: 20 }}>
          {i18n.t('invalidBarcode')}
        </p>
      ) : (
        <svg className="barcode-svg" ref={barcodeRef} />
      )}
    </div>
  );
});

export default BarcodeView;
