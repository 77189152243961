/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, Dropdown } from 'semantic-ui-react';
import { useStateValue } from '../../../core/context/StateProvider';
import './BookingsScreen.css';
import { createDiverseItem } from '../../../api/ItemAPI';
import { showToast } from '../../../components/general/Toast/Toast';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import PriceInput from '../../../components/general/PriceInput/PriceInput';

const DiverseProductModal = (props) => {
  const { onClose, open, categories } = props;
  const [{ token, shop }] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [newItem, setNewItem] = useState({
    title: '',
    price: 0,
    note: '',
    taxRate: 0.19,
    category: null,
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const getCategoryTranslation = (category) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (
      category.name !== i18n.t('appAllCategories') &&
      lang &&
      category.translations &&
      category.translations.length > 0
    ) {
      const name = category.translations.find((translation) => translation.lang === lang);
      if (name) {
        return name.val;
      }
      const englishName = category.translations.find((translation) => translation.lang === 'en');
      if (englishName) {
        return englishName.val;
      }
      return category.name;
    }
    return category.name;
  };

  const getOriginalCategoryFromName = (categoryName) => {
    if (categories !== null) {
      const category = categories.find((category) =>
        category.translations.find((translation) => translation.val === categoryName)
      );
      if (category) {
        return category.name;
      }
      return categoryName;
    }
    return categoryName;
  };

  useEffect(() => {
    if (
      open &&
      newItem.title &&
      newItem.title.length > 0 &&
      newItem.price !== null &&
      newItem.category !== null
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [newItem]);

  const closeModal = (item, note) => {
    setIsLoading(false);
    setIsSaveDisabled(true);
    setNewItem({ title: '', price: 0, note: '', taxRate: 0.19, category: null });
    onClose(item, note);
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const createNewDiverseItem = async () => {
    const data = {
      title: newItem.title,
      price: newItem.price,
      taxRate: newItem.taxRate,
      category: newItem.category,
    };
    try {
      const res = await createDiverseItem(token, data);
      if (res && res.success) {
        closeModal(res.item, newItem.note);
      } else {
        showToast({
          error: true,
          title: i18n.t('newPrinterErrorTitle'),
          message: i18n.t('itemCreateError'),
        });
      }
    } catch (error) {
      showToast({
        error: true,
        title: i18n.t('newPrinterErrorTitle'),
        message: i18n.t('itemCreateError'),
      });
    }
  };

  const dropdownOptions = (categories) => {
    if (categories) {
      const options = [];
      const translatedCategories = categories.map((category) => getCategoryTranslation(category));
      translatedCategories.sort();
      translatedCategories.forEach((category) => {
        const object = {};
        object.key = category.key;
        object.value = category;
        object.text = category;
        options.push(object);
      });
      return options;
    }
    return [];
  };

  const onDropdownChange = (value) => {
    const category = categories.find(
      (category) => category.name === getOriginalCategoryFromName(value)
    );
    setNewItem({ ...newItem, category });
  };

  return (
    <>
      <AnimatedModal
        open={open}
        dimmer="blurring"
        size="tiny"
        closeOnEscape
        closeOnDimmerClick
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{ borderBottom: 'none', backgroundColor: '#f5f5f9', fontFamily: 'Montserrat' }}
        >
          {i18n.t('diverseItem')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal()}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content style={{ backgroundColor: '#f5f5f9', paddingTop: 0, paddingBottom: 0 }}>
          <div style={{ backgroundColor: 'white', borderRadius: 14, padding: 12 }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                gap: 10,
                justifyContent: 'space-between',
                marginBottom: 20,
              }}
            >
              <div style={{ width: '100%' }}>
                <p style={{ fontWeight: 600 }}>{i18n.t('itemsTitle')}*</p>
                <input
                  className="item-modal-input"
                  placeholder={i18n.t('itemsTitle')}
                  value={newItem.title}
                  onChange={(e) => {
                    const trimmedVal = e.target.value ? e.target.value.trim() : null;
                    setNewItem({ ...newItem, title: trimmedVal || null });
                  }}
                />
              </div>
              <div style={{ width: '60%' }}>
                <p style={{ fontWeight: 600 }}>{i18n.t('itemsPrice')}*</p>
                <PriceInput
                  price={newItem.price}
                  onChange={(value) => {
                    setNewItem({ ...newItem, price: value });
                  }}
                />
              </div>
            </div>
            <div
              style={{ display: 'flex', width: '100%', gap: 10, justifyContent: 'space-between' }}
            >
              <div style={{ width: '100%' }}>
                <p style={{ fontWeight: 600 }}>{i18n.t('itemsCategories')}*</p>
                <Dropdown
                  selection
                  placeholder={i18n.t('itemsCategories')}
                  fluid
                  defaultValue={newItem.category}
                  onChange={(_, data) => onDropdownChange(data.value)}
                  options={dropdownOptions(categories)}
                  className="items-modal-dropdown-new"
                  id="items-dropdown"
                  style={{ marginTop: 0 }}
                />
              </div>
              <div style={{ width: '60%' }}>
                <p style={{ fontWeight: 600 }}>{i18n.t('taxRate')}*</p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 14,
                    alignItems: 'baseline',
                  }}
                >
                  <Button.Group
                    style={{
                      fontFamily: 'Montserrat',
                      border: '1px solid #d7d3f6',
                      borderRadius: 6,
                      height: 40,
                      overflow: 'hidden',
                      marginBottom: 8,
                      width: '100%',
                    }}
                  >
                    <Button
                      className="item-modal-buttongroup-button"
                      style={{
                        borderRight: '1px solid #d7d3f6',
                        backgroundColor: newItem.taxRate === 0.07 ? 'white' : '#e6e3fa',
                        color: newItem.taxRate === 0.07 ? '#7268cdc2' : '#7268cd',
                        minHeight: 40,
                      }}
                      onClick={() => {
                        setNewItem({ ...newItem, taxRate: 0.19 });
                      }}
                    >
                      19%
                    </Button>
                    <Button
                      className="item-modal-buttongroup-button"
                      style={{
                        backgroundColor: newItem.taxRate === 0.07 ? '#e6e3fa' : 'white',
                        color: newItem.taxRate === 0.07 ? '#7268cd' : '#7268cdc2',
                        minHeight: 40,
                      }}
                      onClick={() => {
                        setNewItem({ ...newItem, taxRate: 0.07 });
                      }}
                    >
                      7%
                    </Button>
                  </Button.Group>
                </div>
              </div>
            </div>
            <p style={{ fontWeight: 600 }}>{i18n.t('appNotes')}</p>
            <textarea
              style={{
                fontFamily: 'Montserrat',
                width: '100%',
                border: '1px solid #dedede',
                backgroundColor: '#f5f5f9',
                borderRadius: 10,
                resize: 'none',
                padding: 10,
                height: '100%',
              }}
              value={newItem.note}
              className="item-modal-textarea"
              placeholder={i18n.t('appNotes')}
              onChange={(e) => {
                setNewItem({ ...newItem, note: e.target.value });
              }}
            />
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          <Button
            content={i18n.t('add')}
            color="teal"
            onClick={() => createNewDiverseItem()}
            disabled={isSaveDisabled}
            loading={isLoading}
          />
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default DiverseProductModal;
