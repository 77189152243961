/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import { TextArea } from 'semantic-ui-react';
import Checkbox from '../../../components/general/Checkbox/Checkbox';
import { useStateValue } from '../../../core/context/StateProvider';
import { hexToRgb } from '../../../utils/ColorFormatter';
import './CustomerItem.css';
import { asCurrency } from '../../../utils/NumberFormatter';

const CustomerItem = (props) => {
  const [{ shop, config, cart, table }, dispatch] = useStateValue();
  const { item, isOpen, onClose, allCategories } = props;
  const [itemSettings, setItemSettings] = useState({
    totalPrice: 0,
    specialSelections: [],
    notes: '',
    optionGroups: [],
  });

  const darkMode =
    (shop && shop.darkMode) || window.matchMedia('(prefers-color-scheme: dark)').matches;
  const color =
    (shop.color === '#ffffff' || shop.color === '#fff') && darkMode === false
      ? '#000000'
      : (shop.color === '#ffffff' ||
          shop.color === '#fff' ||
          shop.color === '#000000' ||
          shop.color === '#000') &&
        darkMode
      ? '#bebebe'
      : shop.color;
  const rgbShopColor = hexToRgb(color);
  const darkModeBackground = '#0a0a0af2';

  useEffect(() => {
    if (
      item &&
      item.specialSelections.length > 0 &&
      itemSettings.specialSelections.length === 0 &&
      itemSettings.totalPrice === 0 &&
      item.price !== 0
    ) {
      setItemSettings({
        ...itemSettings,
        totalPrice: item.price,
        specialSelections: Array(item.specialSelections.length).fill(false),
      });
    } else if (
      item &&
      item.specialSelections.length === 0 &&
      itemSettings.totalPrice === 0 &&
      item.price !== 0
    ) {
      setItemSettings({
        ...itemSettings,
        totalPrice: item.price,
        specialSelections: [],
      });
    }
  });

  const closeModal = (shouldUpdateStripe, bookedItems) => {
    setItemSettings({ totalPrice: 0, specialSelections: [], notes: '', optionGroups: [] });
    onClose(shouldUpdateStripe, bookedItems);
  };

  const onChangeCheckbox = (event, index) => {
    const specialSelections = [...itemSettings.specialSelections];
    specialSelections[index] = event.target.checked;
    const newPrice = event.target.checked
      ? itemSettings.totalPrice + item.specialSelections[index].price
      : itemSettings.totalPrice - item.specialSelections[index].price;
    setItemSettings({
      ...itemSettings,
      totalPrice: newPrice,
      specialSelections: [...specialSelections],
    });
  };

  const onChangeRadio = (event, indexGroup, indexOption, item) => {
    const optionGroups = [...itemSettings.optionGroups];
    optionGroups[indexGroup] = item.optionGroups[indexGroup];
    if (optionGroups[indexGroup].options[indexOption].checked !== true) {
      optionGroups[indexGroup].options[indexOption].checked = true;
      let priceDeduction = 0;
      optionGroups[indexGroup].options.forEach((option, index) => {
        if (option.checked === true && index !== indexOption) {
          // eslint-disable-next-line no-param-reassign
          option.checked = false;
          priceDeduction += option.price;
        }
      });
      const newPrice =
        itemSettings.totalPrice -
        priceDeduction +
        optionGroups[indexGroup].options[indexOption].price;
      setItemSettings({ ...itemSettings, totalPrice: newPrice, optionGroups: [...optionGroups] });
    }
  };

  const getCategoryTranslation = (categoryName) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (allCategories != null) {
      const category = allCategories.find((category) => category.name === categoryName);
      if (category && lang && category.translations && category.translations.length > 0) {
        const name = category.translations.find((translation) => translation.lang === lang);
        if (name) {
          return name.val;
        }
        const englishName = category.translations.find((translation) => translation.lang === 'en');
        if (englishName) {
          return englishName.val;
        }
        return category.name;
      }
    }
    return categoryName;
  };

  const renderSpecialSelections = () =>
    item.specialSelections.map((specialSelection, index) => (
      <Fragment key={`checkbox-${index}`}>
        <div className="customer-item-screen-special-selection-container">
          <Checkbox
            text={specialSelection.name}
            onChange={(event) => onChangeCheckbox(event, index)}
            checked={
              itemSettings.specialSelections.length > 0 && itemSettings.specialSelections[index]
            }
            labelStyle={{
              paddingLeft: '10px',
              fontSize: '14px',
              fontWeight: 500,
              wordBreak: 'break-all',
              width: '100%',
              color: darkMode ? '#e1dfe6' : '',
            }}
          />
          <p
            style={{
              fontSize: '14px',
              fontWeight: 500,
              width: 110,
              textAlign: 'right',
              color: darkMode ? '#e1dfe6' : '',
            }}
          >
            {`${asCurrency(specialSelection.price / 100)} €`}
          </p>
        </div>
      </Fragment>
    ));

  const renderOptionGroups = (item) => {
    if (
      itemSettings.optionGroups.length === 0 &&
      item.optionGroups &&
      item.optionGroups.length > 0 &&
      itemSettings.optionGroups.length !== item.optionGroups.length
    ) {
      itemSettings.optionGroups = item.optionGroups;
      let newPrice = 0;
      itemSettings.optionGroups.forEach((optionGroup) => {
        optionGroup.options.forEach((option, index) => {
          if (index === 0) {
            // eslint-disable-next-line no-param-reassign
            option.checked = true;
            newPrice += option.price;
          } else {
            // eslint-disable-next-line no-param-reassign
            option.checked = false;
          }
        });
      });
      setItemSettings({
        ...itemSettings,
        optionGroups: itemSettings.optionGroups,
        totalPrice: item.price + newPrice,
      });
    }
    return item.optionGroups ? (
      item.optionGroups.map((optionGroup, index) => (
        <Fragment key={`bc-og-${index}`}>
          <p
            className="customer-item-screen-subtitles"
            style={{ color: darkMode ? '#e1dfe6' : '' }}
          >
            {optionGroup.title}
          </p>
          {optionGroup.options.map((option, idx) => (
            <div
              className="customer-item-screen-special-selection-container"
              onClick={(event) => onChangeRadio(event, index, idx, item)}
              key={`bc-og-${index}${idx}${option.name}`}
            >
              <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                <input
                  type="radio"
                  value={option.name}
                  checked={
                    itemSettings.optionGroups &&
                    itemSettings.optionGroups.length > 0 &&
                    itemSettings.optionGroups[index].options[idx].checked === true
                  }
                  onChange={() => {}}
                />
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    wordBreak: 'break-all',
                    width: '100%',
                    color: darkMode ? '#e1dfe6' : '',
                  }}
                >
                  {option.name}
                </p>
              </div>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  width: 110,
                  textAlign: 'right',
                  color: darkMode ? '#e1dfe6' : '',
                }}
              >
                {`${asCurrency(option.price / 100)} €`}
              </p>
            </div>
          ))}
        </Fragment>
      ))
    ) : (
      <></>
    );
  };

  const renderIngredients = () => (
    <>
      <p className="customer-item-screen-textlist" style={{ color: darkMode ? '#e1dfe6' : '' }}>
        {item.ingredients.join(', ')}
      </p>
    </>
  );

  const renderAllergens = () => (
    <>
      <p className="customer-item-screen-textlist" style={{ color: darkMode ? '#e1dfe6' : '' }}>
        {item.allergens.join(', ')}
      </p>
    </>
  );

  return (
    <>
      {isOpen ? (
        <div className="customer-item-screen-container nselectable">
          {item.picture !== 'none' ? (
            <img
              src={`${config.cloudinaryUrlPrefix + item.picture}`}
              alt="Product"
              className="customer-item-screen-image"
            />
          ) : (
            <div
              className="customer-item-screen-image"
              style={{
                backgroundColor: color,
              }}
            />
          )}
          <div
            style={{
              backgroundColor: darkMode ? 'black' : '#fff',
              position: 'absolute',
              top: 16,
              left: 20,
              height: 30,
              width: 30,
              color: darkMode ? color : `#333`,
              borderRadius: 15,
              cursor: 'pointer',
              border: '1px solid #aaa',
            }}
            onClick={() => closeModal(false, null)}
          >
            <div
              style={{
                backgroundColor: darkMode
                  ? 'transparent'
                  : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.06)`,
                borderRadius: 13,
              }}
            >
              <p style={{ textAlign: 'center', fontSize: 24, fontWeight: 500, marginTop: -2 }}>×</p>
            </div>
          </div>
          <div
            className="customer-item-screen-scroll-container unselectable"
            style={{ backgroundColor: darkMode ? darkModeBackground : '' }}
          >
            <div
              className="customer-item-screen-scroll-container-inside unselectable"
              style={{
                backgroundColor: darkMode
                  ? darkModeBackground
                  : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.06)`,
              }}
            >
              <div className="customer-item-screen-header unselectable">
                <p
                  className="customer-item-screen-title unselectable"
                  style={{ color: darkMode ? '#e1dfe6' : '' }}
                >
                  {item.title}
                </p>
                <p
                  className="customer-item-screen-price"
                  style={{ color: darkMode ? '#e1dfe6' : '' }}
                >
                  {`${asCurrency(item.price / 100)} €`}
                </p>
              </div>
              <p className="customer-item-screen-category">
                {getCategoryTranslation(item.categoryName)}
              </p>
              <p
                className="customer-item-screen-description"
                style={{ color: darkMode ? '#e1dfe6' : '' }}
              >
                {item.description}
              </p>
              {item.optionGroups && item.optionGroups.length > 0 && <>{renderOptionGroups(item)}</>}
              {item.specialSelections && item.specialSelections.length > 0 ? (
                <>
                  <p
                    className="customer-item-screen-subtitles"
                    style={{ color: darkMode ? '#e1dfe6' : '' }}
                  >
                    {i18n.t('itemsSpecialSelections')}
                  </p>
                  {renderSpecialSelections()}
                </>
              ) : (
                <></>
              )}
              {item.ingredients && item.ingredients.length > 0 ? (
                <>
                  <p
                    className="customer-item-screen-subtitles"
                    style={{ color: darkMode ? '#e1dfe6' : '' }}
                  >
                    {i18n.t('itemsIngredients')}
                  </p>
                  {renderIngredients()}
                </>
              ) : (
                <></>
              )}
              {item.allergens && item.allergens.length > 0 ? (
                <>
                  <p
                    className="customer-item-screen-subtitles"
                    style={{ color: darkMode ? '#e1dfe6' : '' }}
                  >
                    {i18n.t('itemsAllergens')}
                  </p>
                  {renderAllergens()}
                </>
              ) : (
                <></>
              )}
              <p
                className="customer-item-screen-subtitles"
                style={{ color: darkMode ? '#e1dfe6' : '' }}
              >
                {i18n.t('appNotes')}
              </p>
              <TextArea
                placeholder={i18n.t('appDescription')}
                className="customer-item-screen-textarea"
                style={{
                  backgroundColor: darkMode ? '#1e1f21' : '',
                  color: darkMode ? 'white' : '',
                }}
                defaultValue={itemSettings.notes}
                maxLength="160"
                onChange={(e) => {
                  setItemSettings({
                    ...itemSettings,
                    notes: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="customer-checkout-button-container unselectable">
            <div
              className="customer-items-checkout-button"
              style={{
                backgroundColor: darkMode ? '#0a0a0a' : color,
                cursor: 'pointer',
                boxShadow: darkMode
                  ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 1) 0px 1px 8px`
                  : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.95) 0px 4px 12px`,
              }}
              onClick={() => {
                if (cart && cart.length < 150) {
                  const bookedItem = {
                    itemId: item._id,
                    categoryName: item.categoryName,
                    itemTitle: item.title,
                    specialSelections: item.specialSelections
                      .filter((_, index) => itemSettings.specialSelections[index] === true)
                      .map((specialSelection) => ({
                        specialSelectionId: specialSelection._id,
                        specialSelectionTitle: specialSelection.name,
                        specialSelectionPrice: specialSelection.price,
                      })),
                    options: itemSettings.optionGroups.map((optionGroup) => ({
                      name: optionGroup.options.find((option) => option.checked === true).name,
                      price: optionGroup.options.find((option) => option.checked === true).price,
                    })),
                    notes: itemSettings.notes,
                    amount: Math.round(itemSettings.totalPrice),
                    taxRate: table && table.isTakeAway ? item.takeAwayTaxRate : item.inHouseTaxRate,
                  };
                  dispatch({
                    type: 'update',
                    toCart: bookedItem,
                  });
                  closeModal(true, cart ? [...cart, bookedItem] : [bookedItem]);
                }
              }}
            >
              {`${asCurrency(itemSettings.totalPrice / 100)} €`}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomerItem;
