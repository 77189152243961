import React from 'react';
import i18n from '../../../i18n/config';

const TipSelectionButtons = ({ selectedTipOption, onAllowTipsChanged, options }) => {
  const buttonStyle = {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    padding: '7px 12px',
    border: 'none',
    height: '36px',
    flex: '1',
    cursor: 'pointer',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    minHeight: '36px',
    transition: 'background-color 0.2s, color 0.2s',
  };

  const buttons = [
    {
      text: i18n.t('off'),
      value: options.off,
      activeColor: '#e6e3fa',
      activeTextColor: '#7268cd',
      inactiveTextColor: '#7268cdc2',
    },
    {
      text: i18n.t('employee'),
      value: options.employee,
      activeColor: '#e6e3fa',
      activeTextColor: '#7268cd',
      inactiveTextColor: '#7268cdc2',
    },
    {
      text: i18n.t('owner'),
      value: options.owner,
      activeColor: '#e6e3fa',
      activeTextColor: '#7268cd',
      inactiveTextColor: '#7268cdc2',
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        border: '1px solid #d7d3f6',
        borderRadius: '10px',
        overflow: 'hidden',
        marginRight: -2,
      }}
    >
      {buttons.map((button, index) => (
        <button
          type="button"
          key={button.value}
          style={{
            ...buttonStyle,
            borderRight: index < buttons.length - 1 ? '1px solid #d7d3f6' : 'none',
            backgroundColor: selectedTipOption === button.value ? button.activeColor : 'white',
            fontWeight: 700,
            color:
              selectedTipOption === button.value
                ? button.activeTextColor
                : button.inactiveTextColor,
          }}
          onClick={() => {
            onAllowTipsChanged(button.value);
          }}
        >
          {button.text}
        </button>
      ))}
    </div>
  );
};

export default TipSelectionButtons;
