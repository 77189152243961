/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button } from 'semantic-ui-react';
import { createTable, deleteTable, updateTable } from '../../../api/ShopAPI';
import Toggle from '../../../components/general/Toggle/Toggle';
import { useStateValue } from '../../../core/context/StateProvider';
import './TablesScreen.css';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const TablesScreenModal = (props) => {
  const { table, onClose, open, tableNumbers } = props;
  const [{ token, shop }, dispatch] = useStateValue();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [data, setData] = useState({
    table: {
      number: '',
      description: '',
      isTakeAway: false,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (open && table) {
      setData({ table });
    }
  }, [open, table]);

  useEffect(() => {
    if (
      data.table.number.length !== 0 &&
      data.table.description.length !== 0 &&
      (table == null ||
        (table != null &&
          (table.number !== parseInt(data.table.number, 10) ||
            table.description !== data.table.description ||
            table.isTakeAway !== data.table.isTakeAway)))
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [data.table]);

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onSave = async () => {
    setIsLoading(true);
    if (
      ((table && table.number !== parseInt(data.table.number, 10)) || table == null) &&
      tableNumbers.includes(parseInt(data.table.number, 10))
    ) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('tablesNumberError'),
      });
      setIsLoading(false);
      return;
    }
    let tableData = {};
    if (table) {
      if (table.number !== parseInt(data.table.number, 10)) {
        tableData.number = data.table.number;
      }
      if (table.description !== data.table.description) {
        tableData.description = data.table.description;
      }
      if (table.isTakeAway !== data.table.isTakeAway) {
        tableData.isTakeAway = data.table.isTakeAway;
      }
    } else {
      tableData = { ...data.table };
    }
    try {
      const res = table
        ? await updateTable(token, table._id, tableData)
        : await createTable(token, tableData);
      setIsLoading(false);
      if (res && res.success) {
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: table ? i18n.t('tablesUpdateError') : i18n.t('tablesCreateError'),
        });
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: table ? i18n.t('tablesUpdateError') : i18n.t('tablesCreateError'),
      });
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    if (
      shop.tables.length === 1 &&
      (shop.isInHouseTableAllowed === false || shop.isTakeAwayTableAllowed === false)
    ) {
      setErrorWarningModalOptions({
        open: true,
        message: `${i18n.t('tablesDeleteError')} ${i18n.t('lastTableError')}`,
      });
    } else {
      const res = await deleteTable(token, table._id);
      if (res && res.success) {
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: `${i18n.t('tablesDeleteError')} ${i18n.t('unpaidTableError')}`,
        });
      }
    }
  };

  const closeModal = (refreshNeeded) => {
    setData({
      table: {
        number: '',
        description: '',
        isTakeAway: false,
      },
    });
    onClose(refreshNeeded);
  };

  return (
    <>
      <AnimatedModal
        open={open}
        dimmer="blurring"
        size="tiny"
        closeOnEscape
        closeOnDimmerClick
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{
            wordBreak: 'break-all',
            borderBottom: 'none',
            backgroundColor: '#f5f5f9',
            fontFamily: 'Montserrat',
          }}
        >
          {(table ? table.description : null) || i18n.t('tableModalTitle')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            className="bookings-btn"
            circular
          />
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{ scrollbarWidth: 'none', backgroundColor: '#f5f5f9', paddingTop: 4 }}
          className="no-scrollbar"
        >
          <div className="items-modal-header" style={{ width: '100%', gap: 16 }}>
            <div style={{ marginRight: 0, width: '100%' }}>
              <p style={{ fontWeight: 600, fontSize: 14, marginBottom: 14 }}>
                {i18n.t('tableDescription')}
              </p>
              <input
                style={{ backgroundColor: 'white' }}
                className="item-modal-input"
                placeholder={i18n.t('tableDescription')}
                value={data.table.description}
                onChange={(e) => {
                  const trimmedValue =
                    e.target.value != null ? e.target.value.trim() : e.target.value;
                  setData({ table: { ...data.table, description: trimmedValue } });
                }}
              />
            </div>
            <div>
              <p style={{ fontWeight: 600, fontSize: 14, marginBottom: 14 }}>
                {i18n.t('tableNumber')}
              </p>
              <input
                style={{ backgroundColor: 'white', userSelect: 'none' }}
                className="item-modal-input"
                placeholder="#"
                value={data.table.number}
                type="number"
                onKeyDown={(e) => {
                  if (
                    e.key === '.' ||
                    e.key === ',' ||
                    e.key === '-' ||
                    e.key === '+' ||
                    e.key === 'e'
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setData({ table: { ...data.table, number: e.target.value } });
                }}
              />
            </div>
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <p style={{ fontSize: 16 }}>Take-Away QR-Code</p>
            <div style={{ right: '26px' }}>
              <Toggle
                onChange={(e) => {
                  setData({ table: { ...data.table, isTakeAway: e.target.checked } });
                }}
                checked={data.table.isTakeAway}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          <Button
            loading={isLoading}
            content={i18n.t('tableModalSave')}
            disabled={isSaveDisabled}
            floated="right"
            onClick={onSave}
            color="teal"
          />
          {table ? (
            <Button
              loading={isLoading}
              content={i18n.t('itemsDelete')}
              floated="left"
              onClick={onDelete}
              color="red"
            />
          ) : (
            <></>
          )}
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default TablesScreenModal;
